import React, { useState, useEffect, useMemo } from 'react';
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Typography,
  TextField,
  Button,
  IconButton,
  InputAdornment,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  LinearProgress,
  Chip,
  Tabs,
  Tab,
  Tooltip,
  Menu,
  Switch,
  FormControlLabel,
} from '@mui/material';
import {
  Search as SearchIcon,
  FilterList as FilterIcon,
  Download as DownloadIcon,
  Chat as ChatIcon,
  PlayArrow as PlayArrowIcon,
} from '@mui/icons-material';
import { format } from 'date-fns';
import { getCampaignsByClient, getReportByCampaign } from '../services/apiService';
import ProviderSelector from './helper/ProviderSelector';
import ChatModal from './modals/ChatModal';

const ReportNew = () => {
  const [selectedCampaign, setSelectedCampaign] = useState('');
  const [campaigns, setCampaigns] = useState([]);
  const [reports, setReports] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchQuery, setSearchQuery] = useState('');
  const [currentProvider, setCurrentProvider] = useState(localStorage.getItem('provider') || 'plivo');
  const [chatModalOpen, setChatModalOpen] = useState(false);
  const [selectedChat, setSelectedChat] = useState(null);
  const [selectedTab, setSelectedTab] = useState(0);
  const [filterOpen, setFilterOpen] = useState(false);
  const [minDuration, setMinDuration] = useState('');
  const [chatSearch, setChatSearch] = useState('');
  const [sortDuration, setSortDuration] = useState('none');
  const [expandedExplanations, setExpandedExplanations] = useState({});
  const [hasConversationName, setHasConversationName] = useState(false);

  // Load campaigns on mount
  useEffect(() => {
    const loadCampaigns = async () => {
      try {
        setLoading(true);
        setError(null);
        const auth = JSON.parse(localStorage.getItem('auth'));
        if (!auth?._id) {
          throw new Error('No client ID found');
        }
        const data = await getCampaignsByClient(auth._id);
        if (Array.isArray(data)) {
          // Filter out any malformed campaign data
          const validCampaigns = data.filter(campaign => 
            campaign && typeof campaign === 'object' && campaign.hasOwnProperty('_id')
          );
          setCampaigns(validCampaigns);
          if (validCampaigns.length === 0) {
            setError('No valid campaigns found');
          }
        } else {
          console.error('Invalid campaigns data:', data);
          setError('Invalid campaign data received');
        }
      } catch (error) {
        console.error('Error loading campaigns:', error);
        setError(error.message || 'Failed to load campaigns');
      } finally {
        setLoading(false);
      }
    };

    loadCampaigns();
  }, []);

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
    setPage(0);
  };

  const handleDownloadCSV = () => {
    const filteredData = getFilteredReports();
    const csvData = filteredData.map(report => {
      let structuredData = {};
      try {
        structuredData = report.structuredOutputData ? JSON.parse(report.structuredOutputData) : {};
      } catch (error) {
        console.error('Error parsing structuredOutputData for CSV:', error, report.structuredOutputData);
        structuredData = {};
      }
      return {
        Name: report.firstName || report?.hangupFirstName || 'Unknown',
        Conversation_Name: structuredData?.name || 'N/A',
        Callee: report.To,
        AI_Number: report.From,
        Lead: structuredData.hotLead ? 'Hot Lead' : 
              structuredData.warmLead ? 'Warm Lead' : 'Cold Lead',
        Duration: Math.round(report.Duration),
        'Date Created': formatMongoDate(report._id),
        Chat: report.chat,
        Tag: report.tag,
      };
    });

    const headers = Object.keys(csvData[0]);
    const csvContent = [
      headers.join(','),
      ...csvData.map(row => headers.map(header => 
        JSON.stringify(row[header] || '')).join(','))
    ].join('\n');

    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = `report_${selectedTab === 0 ? 'all' : 
                              selectedTab === 1 ? 'completed_calls' : 
                              selectedTab === 2 ? 'failed_calls' : 
                              selectedTab === 3 ? 'hot_leads' : 
                              selectedTab === 4 ? 'warm_leads' : 
                              'cold_leads'}.csv`;
    link.click();
  };

  const getFilteredReports = () => {
    return reports.filter(report => {
      let structuredData = {};
      try {
        structuredData = report.structuredOutputData ? JSON.parse(report.structuredOutputData) : {};
      } catch (error) {
        console.error('Error parsing structuredOutputData:', error, report.structuredOutputData);
        structuredData = {};
      }
      const duration = Math.round(report.Duration || 0);
      
      // Basic search filter
      if (searchQuery) {
        const searchLower = searchQuery.toLowerCase();
        const name = structuredData.name || '';
        if (!name.toLowerCase().includes(searchLower) &&
            !report.To?.toLowerCase().includes(searchLower) &&
            !report.From?.toLowerCase().includes(searchLower) &&
            !report.CallStatus?.toLowerCase().includes(searchLower)) {
          return false;
        }
      }

      // Has Conversation Name filter
      if (hasConversationName && (!structuredData.name || structuredData.name === 'N/A')) {
        return false;
      }

      // Duration filter
      if (minDuration && duration < parseInt(minDuration)) {
        return false;
      }

      // Chat search filter
      if (chatSearch && !report.chat?.toLowerCase().includes(chatSearch.toLowerCase())) {
        return false;
      }

      // Tab filters
      switch (selectedTab) {
        case 0: // All
          return true;
        case 1: // Completed Calls
          return report.CallStatus === 'completed';
        case 2: // Failed Calls
          return report.CallStatus !== 'completed';
        case 3: // Hot Leads
          return structuredData.hotLead;
        case 4: // Warm Leads
          return structuredData.warmLead;
        case 5: // Cold Leads
          return !structuredData.hotLead && !structuredData.warmLead;
        default:
          return true;
      }
    }).sort((a, b) => {
      if (sortDuration === 'none') return 0;
      const durationA = Math.round(a.Duration || 0);
      const durationB = Math.round(b.Duration || 0);
      return sortDuration === 'asc' ? durationA - durationB : durationB - durationA;
    });
  };

  const formatMongoDate = (objectId) => {
    if (!objectId) return '';
    const timestamp = parseInt(objectId.substring(0, 8), 16) * 1000;
    try {
      return format(new Date(timestamp), 'dd MMM yyyy');
    } catch (error) {
      console.error('Error formatting date:', error);
      return 'Invalid date';
    }
  };

  // Load reports when campaign is selected
  const handleCampaignChange = async (event) => {
    const campaignId = event.target.value;
    setSelectedCampaign(campaignId);
    
    if (campaignId) {
      setLoading(true);
      setError(null);
      try {
        const auth = JSON.parse(localStorage.getItem('auth'));
        const selectedCamp = campaigns.find(c => c._id === campaignId);
        const response = await getReportByCampaign(campaignId, selectedCamp?.name || '');
        
        if (response) {
          setReports(response);
        } else {
          setReports([]);
          setError('No reports found for this campaign');
        }
      } catch (error) {
        console.error('Error loading reports:', error);
        setError(error.message || 'Failed to load reports');
        setReports([]);
      } finally {
        setLoading(false);
      }
    } else {
      setReports([]);
    }
  };

  const handleViewChat = (report) => {
    if (report.chat && report.chat.trim()) {
      setSelectedChat(report);
      setChatModalOpen(true);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const filteredReports = useMemo(getFilteredReports, [
    reports, searchQuery, minDuration, chatSearch, selectedTab, sortDuration, hasConversationName
  ]);

  const paginatedReports = useMemo(() => {
    return filteredReports.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
  }, [filteredReports, page, rowsPerPage]);

  return (
    <Box sx={{ p: 3 }}>
      <Box sx={{ 
        display: 'flex', 
        justifyContent: 'space-between', 
        alignItems: 'center',
        mb: 3 
      }}>
        <Typography variant="h5" sx={{ color: '#5f6368', fontWeight: 500 }}>
          Campaign Reports
        </Typography>

        <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
          <ProviderSelector 
            value={currentProvider} 
            onChange={(value) => setCurrentProvider(value)} 
          />
        </Box>
      </Box>

      <Box sx={{ 
        mb: 3,
        display: 'flex',
        gap: 2,
        alignItems: 'center'
      }}>
        <FormControl sx={{ minWidth: 200 }}>
          <InputLabel sx={{ color: '#616161' }}>Select Campaign</InputLabel>
          <Select
            value={selectedCampaign}
            onChange={handleCampaignChange}
            label="Select Campaign"
            sx={{
              color: '#616161',
              '& .MuiOutlinedInput-notchedOutline': {
                borderColor: '#e0e0e0'
              }
            }}
            MenuProps={{
              PaperProps: {
                sx: {
                  bgcolor: '#faf8f6'
                }
              }
            }}
          >
            <MenuItem value="" sx={{ color: '#616161' }}>
              <em>None</em>
            </MenuItem>
            {campaigns.map((campaign) => (
              <MenuItem key={campaign._id} value={campaign._id} sx={{ color: '#616161' }}>
                {campaign.name || campaign.campaignName || 'Unnamed Campaign'}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <TextField
          size="small"
          placeholder="Search by name or number..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon sx={{ color: '#616161' }} />
              </InputAdornment>
            ),
          }}
          sx={{
            flex: 1,
            maxWidth: 300,
            '& .MuiOutlinedInput-root': {
              color: '#616161',
              '& fieldset': {
                borderColor: '#e0e0e0'
              },
              '&:hover fieldset': {
                borderColor: '#bdbdbd'
              }
            }
          }}
        />

        <Tooltip title="Filters">
          <IconButton 
            onClick={() => setFilterOpen(!filterOpen)}
            sx={{ 
              color: filterOpen ? '#1976d2' : '#5f6368',
              bgcolor: filterOpen ? 'rgba(25, 118, 210, 0.08)' : 'transparent'
            }}
          >
            <FilterIcon />
          </IconButton>
        </Tooltip>

        <Tooltip title="Download CSV">
          <IconButton
            onClick={handleDownloadCSV}
            disabled={!filteredReports.length}
            sx={{ 
              color: '#5f6368',
              '&:disabled': {
                color: 'rgba(95, 99, 104, 0.3)'
              }
            }}
          >
            <DownloadIcon />
          </IconButton>
        </Tooltip>
      </Box>

      {filterOpen && (
        <Paper sx={{ p: 2, mb: 3, bgcolor: '#ffffff' }}>
          <Box sx={{ display: 'flex', gap: 2, alignItems: 'center', flexWrap: 'wrap' }}>
            <TextField
              size="small"
              label="Min Duration (seconds)"
              type="number"
              value={minDuration}
              onChange={(e) => setMinDuration(e.target.value)}
              sx={{ 
                width: 180,
                '& .MuiOutlinedInput-root': {
                  color: '#616161',
                  '& fieldset': {
                    borderColor: '#e0e0e0'
                  }
                },
                '& .MuiInputLabel-root': {
                  color: '#616161'
                }
              }}
            />

            <TextField
              size="small"
              label="Search in Chat"
              value={chatSearch}
              onChange={(e) => setChatSearch(e.target.value)}
              sx={{ 
                width: 200,
                '& .MuiOutlinedInput-root': {
                  color: '#616161',
                  '& fieldset': {
                    borderColor: '#e0e0e0'
                  }
                },
                '& .MuiInputLabel-root': {
                  color: '#616161'
                }
              }}
            />

            <FormControl size="small">
              <InputLabel sx={{ color: '#616161' }}>Sort Duration</InputLabel>
              <Select
                value={sortDuration}
                onChange={(e) => setSortDuration(e.target.value)}
                label="Sort Duration"
                sx={{
                  color: '#616161',
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: '#e0e0e0'
                  }
                }}
                MenuProps={{
                  PaperProps: {
                    sx: {
                      bgcolor: '#faf8f6'
                    }
                  }
                }}
              >
                <MenuItem value="none" sx={{ color: '#616161' }}>None</MenuItem>
                <MenuItem value="asc" sx={{ color: '#616161' }}>Ascending</MenuItem>
                <MenuItem value="desc" sx={{ color: '#616161' }}>Descending</MenuItem>
              </Select>
            </FormControl>

            <FormControlLabel
              control={
                <Switch
                  checked={hasConversationName}
                  onChange={(e) => setHasConversationName(e.target.checked)}
                  size="small"
                  sx={{
                    '& .MuiSwitch-track': {
                      backgroundColor: '#e0e0e0'
                    },
                    '& .Mui-checked + .MuiSwitch-track': {
                      backgroundColor: '#bdbdbd'
                    }
                  }}
                />
              }
              label="Has Conversation Name"
              sx={{ 
                color: '#616161'
              }}
            />
          </Box>
        </Paper>
      )}

      <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 2 }}>
        <Tabs 
          value={selectedTab} 
          onChange={handleTabChange}
          sx={{
            '& .MuiTab-root': {
              color: '#5f6368',
              '&.Mui-selected': {
                color: '#1976d2'
              }
            }
          }}
        >
          <Tab label="All" />
          <Tab label="Completed Calls" />
          <Tab label="Failed Calls" />
          <Tab label="Hot Leads" />
          <Tab label="Warm Leads" />
          <Tab label="Cold Leads" />
        </Tabs>
      </Box>

      {loading && <LinearProgress sx={{ mb: 2 }} />}

      {error && (
        <Typography color="error" sx={{ mb: 2 }}>
          {error}
        </Typography>
      )}

      <TableContainer component={Paper} sx={{ bgcolor: '#faf8f6' }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={{ color: '#616161', fontWeight: 500 }}>Name</TableCell>
              <TableCell sx={{ color: '#616161', fontWeight: 500 }}>Callee</TableCell>
              <TableCell sx={{ color: '#616161', fontWeight: 500 }}>AI Number</TableCell>
              <TableCell sx={{ color: '#616161', fontWeight: 500 }}>Conversation Name</TableCell>
              <TableCell sx={{ color: '#616161', fontWeight: 500 }}>Status</TableCell>
              <TableCell sx={{ color: '#616161', fontWeight: 500 }}>Lead</TableCell>
              <TableCell sx={{ color: '#616161', fontWeight: 500 }}>Duration</TableCell>
              <TableCell sx={{ color: '#616161', fontWeight: 500 }}>Date Created</TableCell>
              <TableCell sx={{ color: '#616161', fontWeight: 500 }}>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {paginatedReports.map((report) => {
              let structuredData = {};
              try {
                structuredData = report.structuredOutputData ? JSON.parse(report.structuredOutputData) : {};
              } catch (error) {
                console.error('Error parsing structuredOutputData:', error, report.structuredOutputData);
                structuredData = {};
              }
              return (
                <TableRow key={report._id} sx={{ '& .MuiTableCell-root': { color: '#616161' } }}>
                  <TableCell>{report.firstName ||report?.hangupFirstName || 'Unknown'}</TableCell>
                  <TableCell>{report.To}</TableCell>
                  <TableCell>{report.From}</TableCell>
                  <TableCell>{structuredData.name || 'N/A'}</TableCell>
                  <TableCell>
                    <Chip 
                      label={report.CallStatus || 'Unknown'} 
                      size="small"
                      sx={{
                        bgcolor: report.CallStatus === 'completed' ? '#e8f5e9' : '#ffebee',
                        color: report.CallStatus === 'completed' ? '#2e7d32' : '#c62828'
                      }}
                    />
                  </TableCell>
                  <TableCell>
                    <Chip 
                      label={
                        structuredData.hotLead ? 'Hot Lead' : 
                        structuredData.warmLead ? 'Warm Lead' : 'Cold Lead'
                      }
                      size="small"
                      sx={{
                        bgcolor: 
                          structuredData.hotLead ? '#ffebee' : 
                          structuredData.warmLead ? '#fff3e0' : '#e3f2fd',
                        color: 
                          structuredData.hotLead ? '#d32f2f' : 
                          structuredData.warmLead ? '#ef6c00' : '#1976d2'
                      }}
                    />
                  </TableCell>
                  <TableCell>{Math.round(report.Duration || 0)}s</TableCell>
                  <TableCell>{formatMongoDate(report._id)}</TableCell>
                  <TableCell>
                    <IconButton
                      size="small"
                      onClick={() => handleViewChat(report)}
                      sx={{ 
                        color: '#616161',
                        '&:hover': {
                          color: '#1976d2'
                        },
                        opacity: report.chat && report.chat.trim() ? 1 : 0.5
                      }}
                    >
                      <ChatIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        component="div"
        count={filteredReports.length}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        sx={{
          bgcolor: '#faf8f6',
          color: '#616161',
          '& .MuiTablePagination-select': {
            color: '#616161'
          }
        }}
      />

      <ChatModal
        open={chatModalOpen}
        onClose={() => {
          setChatModalOpen(false);
          setSelectedChat(null);
        }}
        chat={selectedChat?.chat}
        report={selectedChat}
      />
    </Box>
  );
};

export default ReportNew;

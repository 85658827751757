import React, { useState, useRef, useEffect } from 'react';
import {
  Box,
  Paper,
  Typography,
  TextField,
  Button,
  Container,
  CircularProgress,
  Card,
  CardContent,
  IconButton,
  Divider
} from '@mui/material';
import {
  ArrowBack as ArrowBackIcon,
  Check as CheckIcon,
  Close as CloseIcon,
  Save as SaveIcon,
} from '@mui/icons-material';
import axios from 'axios';
import './TrainAI.css';

function DiffView({ original, suggested, onAccept, onReject }) {
  // Find the different parts
  let start = 0;
  while (start < original.length && start < suggested.length && original[start] === suggested[start]) {
    start++;
  }

  let end = 0;
  while (
    end < original.length - start &&
    end < suggested.length - start &&
    original[original.length - 1 - end] === suggested[suggested.length - 1 - end]
  ) {
    end++;
  }

  const prefix = original.slice(0, start);
  const originalMiddle = original.slice(start, original.length - end);
  const suggestedMiddle = suggested.slice(start, suggested.length - end);
  const suffix = original.slice(original.length - end);

  return (
    <Box sx={{ 
      bgcolor: '#f8f8f8', 
      p: 2, 
      borderRadius: 1,
      mb: 2,
      fontFamily: 'monospace',
      fontSize: '0.875rem'
    }}>
      <Box sx={{ mb: 1 }}>
        <Typography component="div" sx={{ color: '#d32f2f', whiteSpace: 'pre-wrap' }}>
          - {prefix}<span style={{ backgroundColor: '#ffebee' }}>{originalMiddle}</span>{suffix}
        </Typography>
        <Typography component="div" sx={{ color: '#2e7d32', whiteSpace: 'pre-wrap' }}>
          + {prefix}<span style={{ backgroundColor: '#e8f5e9' }}>{suggestedMiddle}</span>{suffix}
        </Typography>
      </Box>
      <Box sx={{ display: 'flex', gap: 1, justifyContent: 'flex-end' }}>
        <Button
          variant="outlined"
          onClick={onReject}
          startIcon={<CloseIcon />}
          sx={{
            color: '#d32f2f',
            borderColor: '#d32f2f',
            '&:hover': {
              borderColor: '#d32f2f',
              bgcolor: '#fff5f5',
            },
          }}
        >
          Reject
        </Button>
        <Button
          variant="contained"
          onClick={onAccept}
          startIcon={<CheckIcon />}
          sx={{
            bgcolor: '#2e7d32',
            '&:hover': {
              bgcolor: '#1b5e20',
            },
          }}
        >
          Accept
        </Button>
      </Box>
    </Box>
  );
}

export default function TrainAI({ assistant, onBack }) {
  const [updatedPrompt, setUpdatedPrompt] = useState(assistant.system_prompt);
  const [welcomeMessage, setWelcomeMessage] = useState(assistant.agent_welcome_message || '');
  const [tempPrompt, setTempPrompt] = useState(assistant.system_prompt);
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [suggestedChanges, setSuggestedChanges] = useState([]);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [hasUnresolvedChanges, setHasUnresolvedChanges] = useState(false);
  const chatEndRef = useRef(null);

  // Scroll to bottom when messages change
  useEffect(() => {
    if (chatEndRef.current) {
      chatEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [messages]);

  useEffect(() => {
    // Start with the initial prompt
    setTempPrompt(assistant.system_prompt);
    setUpdatedPrompt(assistant.system_prompt);
  }, [assistant.system_prompt]);

  useEffect(() => {
    const hasChanges = tempPrompt !== assistant.system_prompt || welcomeMessage !== assistant.agent_welcome_message;
    setHasUnsavedChanges(hasChanges);
  }, [tempPrompt, welcomeMessage, assistant]);

  const handleAcceptChange = async (change) => {
    const newPrompt = tempPrompt.replace(change.original, change.suggested);
    setTempPrompt(newPrompt);
    setSuggestedChanges(prev => prev.filter(c => c !== change));
    setHasUnsavedChanges(true);
    
    // Check if all changes are resolved
    if (suggestedChanges.length <= 1) {
      setHasUnresolvedChanges(false);
    }
  };

  const handleRejectChange = async (change) => {
    setSuggestedChanges(prev => prev.filter(c => c !== change));
    
    // Check if all changes are resolved
    if (suggestedChanges.length <= 1) {
      setHasUnresolvedChanges(false);
    }
  };

  const handleSaveChanges = async () => {
    try {
      setLoading(true);
      await axios.post('https://application.glimpass.com/interlogue/update-assistant', {
        assistantId: assistant._id,
        isClient: 1,
        newDocs: {
          agent_welcome_message: welcomeMessage,
          system_prompt: tempPrompt
        }
      });
      setHasUnsavedChanges(false);
      setMessages(prev => [...prev, {
        type: 'system',
        content: 'Changes saved successfully!'
      }]);
      // Update the local state to match saved state
      setUpdatedPrompt(tempPrompt);
    } catch (error) {
      console.error('Error saving changes:', error);
      setMessages(prev => [...prev, {
        type: 'error',
        content: 'Failed to save changes. Please try again.'
      }]);
    } finally {
      setLoading(false);
    }
  };

  const handleSendMessage = async () => {
    if (!inputMessage.trim()) return;

    const messageToSend = inputMessage;
    setMessages(prev => [...prev, { type: 'user', content: messageToSend }]);
    setInputMessage('');
    setLoading(true);

    try {
      const response = await axios.post('https://train-ai.markaible.com/api/train-ai/chat', {
        assistantId: assistant.id,
        assistantName: assistant.agent_name,
        prompt: tempPrompt,
        question: messageToSend
      });

      const result = response.data.response;
      
      if (result.type === 'changes' && result.changes) {
        setSuggestedChanges(result.changes);
        setMessages(prev => [...prev, {
          type: 'ai',
          content: result.analysis
        }]);
      } else {
        setMessages(prev => [...prev, {
          type: 'ai',
          content: result.content
        }]);
      }
    } catch (error) {
      console.error('Error sending message:', error);
      setMessages(prev => [...prev, {
        type: 'error',
        content: 'Failed to process your request. Please try again.'
      }]);
    } finally {
      setLoading(false);
    }
  };

  const [inputMessage, setInputMessage] = useState('');

  return (
    <Container maxWidth="lg" sx={{ height: '100vh', display: 'flex', flexDirection: 'column', py: 2 }}>
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
        <IconButton onClick={onBack} sx={{ mr: 2, color: '#666' }}>
          <ArrowBackIcon />
        </IconButton>
        <Typography variant="h4" sx={{ color: '#666' }}>
          Training AI: {assistant.agent_name}
        </Typography>
      </Box>

      <Box sx={{ display: 'flex', gap: 3, flex: 1, minHeight: 0 }}>
        {/* Left Panel - System Prompt */}
        <Card sx={{ 
          flex: 1, 
          display: 'flex', 
          flexDirection: 'column',
          bgcolor: '#fefefe',
          boxShadow: '0 2px 4px rgba(0,0,0,0.05)'
        }}>
          <CardContent sx={{ flex: 1, display: 'flex', flexDirection: 'column', overflow: 'hidden', pt: 0.5 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 1.5, mt: 0.5 }}>
              <Typography variant="body2" sx={{ color: '#666', minWidth: '100px', fontWeight: 500 }}>
                First Message:
              </Typography>
              <TextField
                size="small"
                value={welcomeMessage}
                onChange={(e) => {
                  setWelcomeMessage(e.target.value);
                  setHasUnsavedChanges(true);
                }}
                placeholder="Hi, how can I help you?"
                variant="outlined"
                sx={{ 
                  maxWidth: '250px',
                  flex: 1,
                  mr: 2,
                  '& .MuiOutlinedInput-root': {
                    height: '30px',
                    fontSize: '0.875rem',
                    backgroundColor: '#f8f9fa',
                    '&:hover': {
                      backgroundColor: '#f8f9fa'
                    },
                    '& fieldset': {
                      borderColor: '#e0e0e0'
                    }
                  }
                }}
              />
              {hasUnsavedChanges && (
                <Button
                  variant="contained"
                  onClick={handleSaveChanges}
                  startIcon={<SaveIcon />}
                  size="small"
                  sx={{
                    height: '30px',
                    minWidth: '100px',
                    bgcolor: '#1976d2',
                    '&:hover': {
                      bgcolor: '#1565c0',
                    },
                  }}
                >
                  Save Changes
                </Button>
              )}
            </Box>

            <Divider sx={{ mb: 1.5 }} />

            <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
              <Typography variant="subtitle1" sx={{ color: '#666', fontWeight: 500 }}>
                System Prompt
              </Typography>
            </Box>
            <Box sx={{ flex: 1, overflow: 'auto' }}>
              {/* Display diffs above the text area */}
              {suggestedChanges.length > 0 && (
                <Box sx={{ mb: 2, p: 2, bgcolor: '#f8f9fa', borderRadius: 1, border: '1px solid #e0e0e0' }}>
                  <Typography variant="body2" sx={{ mb: 1, fontWeight: 500, color: '#555' }}>
                    Suggested Changes {hasUnresolvedChanges && <span style={{ color: '#d32f2f' }}>(Please resolve all changes before editing)</span>}
                  </Typography>
                  {suggestedChanges.map((change, index) => (
                    <DiffView
                      key={index}
                      original={change.original}
                      suggested={change.suggested}
                      onAccept={() => handleAcceptChange(change)}
                      onReject={() => handleRejectChange(change)}
                    />
                  ))}
                </Box>
              )}
              
              <TextField
                fullWidth
                multiline
                rows={22}
                value={tempPrompt}
                onChange={(e) => {
                  if (!hasUnresolvedChanges) {
                    setTempPrompt(e.target.value);
                    setHasUnsavedChanges(true);
                  }
                }}
                disabled={hasUnresolvedChanges}
                placeholder={hasUnresolvedChanges ? "Please resolve all suggested changes first" : "Enter system prompt"}
                variant="outlined"
                sx={{
                  '& .MuiOutlinedInput-root': {
                    bgcolor: hasUnresolvedChanges ? '#f5f5f5' : '#fff',
                    '& fieldset': {
                      border: `1px solid ${hasUnresolvedChanges ? '#bdbdbd' : '#e0e0e0'}`
                    }
                  },
                  '& .MuiInputBase-input': {
                    fontFamily: 'monospace',
                    fontSize: '0.9rem',
                    lineHeight: '1.5',
                    pt: 1.5
                  }
                }}
              />
            </Box>
          </CardContent>
        </Card>

        {/* Right Panel - Chat Interface */}
        <Card sx={{ 
          width: '40%', 
          display: 'flex', 
          flexDirection: 'column',
          bgcolor: '#fefefe',
          boxShadow: '0 2px 4px rgba(0,0,0,0.05)'
        }}>
          <CardContent sx={{ 
            flex: 1, 
            display: 'flex', 
            flexDirection: 'column', 
            overflow: 'hidden',
          }}>
            <Typography variant="h6" sx={{ mb: 2, color: '#666' }}>
              Chat with {assistant.agent_name}
            </Typography>
            <Box sx={{ flex: 1, overflow: 'auto', mb: 2 }}>
              {messages.map((msg, index) => (
                <Box
                  key={index}
                  sx={{
                    mb: 2,
                    p: 1.5,
                    borderRadius: 1,
                    bgcolor: msg.type === 'user' ? '#f8f9fa' : 
                            msg.type === 'error' ? '#fff5f5' : 
                            msg.type === 'system' ? '#f8f9fa' : '#fff',
                    border: '1px solid',
                    borderColor: msg.type === 'user' ? '#e9ecef' :
                               msg.type === 'error' ? '#ffe3e3' :
                               msg.type === 'system' ? '#e9ecef' : '#e9ecef',
                  }}
                >
                  <Typography sx={{ 
                    whiteSpace: 'pre-wrap',
                    color: msg.type === 'error' ? '#e03131' : '#666'
                  }}>
                    {msg.content}
                  </Typography>
                </Box>
              ))}
              {loading && (
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, color: '#666' }}>
                  <CircularProgress size={16} sx={{ color: '#666' }} />
                  <Typography sx={{ color: '#666' }}>Thinking...</Typography>
                </Box>
              )}
              <div ref={chatEndRef} />
            </Box>
            <Box sx={{ display: 'flex', gap: 1 }}>
              <TextField
                fullWidth
                value={inputMessage}
                onChange={(e) => setInputMessage(e.target.value)}
                onKeyPress={(e) => e.key === 'Enter' && handleSendMessage()}
                placeholder="Ask a question or suggest changes..."
                disabled={loading}
                sx={{ 
                  '& .MuiInputBase-input': {
                    color: '#666',
                  },
                  '& .MuiOutlinedInput-root': {
                    bgcolor: '#fff',
                    '&:hover': {
                      bgcolor: '#fafafa',
                    },
                  },
                }}
              />
              <Button
                variant="contained"
                onClick={handleSendMessage}
                disabled={loading || !inputMessage.trim()}
                sx={{
                  bgcolor: '#666',
                  '&:hover': {
                    bgcolor: '#555',
                  },
                  '&.Mui-disabled': {
                    bgcolor: '#ccc',
                  },
                }}
              >
                Send
              </Button>
            </Box>
          </CardContent>
        </Card>
      </Box>
    </Container>
  );
}

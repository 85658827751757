import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Box,
  Typography,
  Paper,
  Button,
  Divider,
} from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';

const ChatModal = ({ open, onClose, chat, report }) => {
  const formatMessages = (chatString) => {
    if (!chatString) return [];
    
    return chatString.split('|')
      .map(msg => msg.trim())
      .filter(msg => msg)
      .map(msg => {
        if (msg.startsWith('human:')) {
          return {
            role: 'user',
            content: msg.replace('human:', '').trim()
          };
        } else if (msg.startsWith('AI:')) {
          return {
            role: 'ai',
            content: msg.replace('AI:', '').trim()
          };
        }
        const [role, ...contentParts] = msg.split(':');
        if (contentParts.length > 0) {
          return {
            role: role.toLowerCase().includes('human') ? 'user' : 'ai',
            content: contentParts.join(':').trim()
          };
        }
        return null;
      })
      .filter(msg => msg && msg.content);
  };

  const messages = formatMessages(chat);
  const structuredData = report ? JSON.parse(report.structuredOutputData || '{}') : {};

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="md"
      fullWidth
      PaperProps={{
        sx: {
          borderRadius: '12px',
          backgroundColor: '#ffffff'
        }
      }}
    >
      <DialogTitle
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          py: 2,
          px: 3,
          color: '#424242',
          borderBottom: '1px solid #f0f0f0'
        }}
      >
        <Typography variant="h6" sx={{ fontWeight: 500 }}>
          Conversation Details
        </Typography>
        <IconButton
          onClick={onClose}
          size="small"
          sx={{ 
            color: '#757575',
            '&:hover': {
              backgroundColor: 'rgba(0, 0, 0, 0.04)'
            }
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent 
        sx={{ 
          p: 3,
          backgroundColor: '#ffffff',
          minHeight: '400px',
          maxHeight: '80vh',
          overflowY: 'auto'
        }}
      >
        {structuredData.explanation && (
          <Paper 
            elevation={0}
            sx={{ 
              mb: 3,
              p: 2.5,
              backgroundColor: '#faf8f6',
              borderRadius: '8px',
              border: '1px solid #f0f0f0'
            }}
          >
            <Typography variant="subtitle1" gutterBottom sx={{ color: '#424242', fontWeight: 500 }}>
              Call Summary
            </Typography>
            <Typography variant="body2" sx={{ color: '#616161', lineHeight: 1.6 }}>
              {structuredData.explanation}
            </Typography>
          </Paper>
        )}

        {report?.RecordUrl && (
          <Paper 
            elevation={0}
            sx={{ 
              mb: 3,
              p: 2.5,
              backgroundColor: '#faf8f6',
              borderRadius: '8px',
              border: '1px solid #f0f0f0'
            }}
          >
            <Typography variant="subtitle1" gutterBottom sx={{ color: '#424242', fontWeight: 500 }}>
              Call Recording
            </Typography>
            <audio 
              controls 
              src={report.RecordUrl} 
              style={{ 
                width: '100%',
                height: '40px',
                borderRadius: '4px'
              }} 
            />
          </Paper>
        )}

        <Divider sx={{ my: 3 }} />

        <Typography variant="subtitle1" sx={{ mb: 2, color: '#424242', fontWeight: 500 }}>
          Chat Transcript
        </Typography>

        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          {messages.map((msg, index) => (
            <Box
              key={index}
              sx={{
                display: 'flex',
                justifyContent: msg.role === 'user' ? 'flex-end' : 'flex-start',
                width: '100%'
              }}
            >
              <Paper
                elevation={0}
                sx={{
                  p: 2,
                  maxWidth: '75%',
                  backgroundColor: msg.role === 'user' ? '#f3f6f9' : '#faf8f6',
                  borderRadius: '12px',
                  border: '1px solid #f0f0f0'
                }}
              >
                <Typography 
                  variant="body2" 
                  sx={{ 
                    color: '#616161',
                    lineHeight: 1.6,
                    whiteSpace: 'pre-wrap'
                  }}
                >
                  {msg.content}
                </Typography>
              </Paper>
            </Box>
          ))}
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default ChatModal;

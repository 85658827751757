import React, { useState, useEffect, useMemo } from 'react';
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Typography,
  TextField,
  Button,
  IconButton,
  InputAdornment,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  Card,
  CardContent,
  Chip,
  Tooltip,
} from '@mui/material';
import {
  Search as SearchIcon,
  Close as CloseIcon,
  Chat as ChatIcon,
  FilterList,
  Refresh as RefreshIcon,
  SortByAlpha as SortIcon,
} from '@mui/icons-material';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { format } from 'date-fns';
import { fetchIncomingCallsByNumber } from '../actions/incomingActions';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import debounce from 'lodash/debounce';
import CustomLoader from './helper/CustomLoader';
import { startOfDay, endOfDay, isWithinInterval } from 'date-fns';
import AudioPlayer from './AudioPlayer';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    backgroundColor: '#f8f9fa',
    minHeight: '100vh',
  },
  filterSection: {
    marginBottom: theme.spacing(2),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: theme.spacing(2),
  },
  filterItem: {
    flex: '1 1 15%',
    minWidth: '200px',
  },
  tableHeadCell: {
    fontWeight: 'bold',
    backgroundColor: '#f1f3f5',
    color: '#495057',
  },
  tableRow: {
    '&:hover': {
      backgroundColor: '#f8f9fa',
    },
  },
  centered: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '50vh',
    textAlign: 'center',
  },
  paginationContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(2),
  },
  gradientButton: {
    background: 'linear-gradient(45deg, #4CAF50 30%, #81C784 90%)',
    color: 'white',
    '&:hover': {
      background: 'linear-gradient(45deg, #388E3C 30%, #66BB6A 90%)',
    },
  },
  hotChip: {
    backgroundColor: '#ffebee',
    color: '#d32f2f',
    fontWeight: 'bold',
  },
  warmChip: {
    backgroundColor: '#fff3e0',
    color: '#ef6c00',
    fontWeight: 'bold',
  },
  coldChip: {
    backgroundColor: '#e3f2fd',
    color: '#1976d2',
    fontWeight: 'bold',
  },
  audioPlayer: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
  },
  chatMessage: {
    padding: theme.spacing(1.5),
    marginBottom: theme.spacing(1.5),
    borderRadius: 8,
  },
  humanMessage: {
    backgroundColor: '#f5f5f5',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(8),
  },
  aiMessage: {
    backgroundColor: '#e3f2fd',
    marginLeft: theme.spacing(8),
    marginRight: theme.spacing(2),
  },
  explanationCard: {
    marginBottom: theme.spacing(2),
    backgroundColor: '#fff8e1',
  }
}));

// Helper function to format phone numbers (remove + prefix)
const formatPhoneNumber = (number) => {
  if (!number) return 'N/A';
  return number.replace(/^\+/, '');
};

const IncomingCalls = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [selectedNumber, setSelectedNumber] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchInput, setSearchInput] = useState('');
  const [sortOrder, setSortOrder] = useState('desc');
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [totalCalls, setTotalCalls] = useState(0);
  const [chatModalOpen, setChatModalOpen] = useState(false);
  const [selectedChat, setSelectedChat] = useState('');
  const [selectedLeadData, setSelectedLeadData] = useState(null);
  const [directCallData, setDirectCallData] = useState([]);
  const [callNumbers, setCallNumbers] = useState([]);

  // Fetch call numbers from auth
  useEffect(() => {
    const authData = JSON.parse(localStorage.getItem('auth'));
    if (authData?.callerNumbers) {
      setCallNumbers(authData.callerNumbers);
      // Set the first number as default selected
      if (authData.callerNumbers.length > 0 && !selectedNumber) {
        setSelectedNumber(authData.callerNumbers[0]);
      }
    } else {
      // Fallback numbers if none in auth
      const fallbackNumbers = ['918035735659', '917834811114'];
      setCallNumbers(fallbackNumbers);
      if (!selectedNumber) {
        setSelectedNumber(fallbackNumbers[0]);
      }
    }
  }, []);

  const handleSearchChange = (e) => {
    const { value } = e.target;
    e.persist();
    setSearchInput(value);
  };

  const handleNumberChange = (e) => {
    const number = e.target.value;
    setSelectedNumber(number);
    setPage(0); // Reset to first page when changing number
  };

  const handleSortOrderChange = () => {
    setSortOrder(prev => prev === 'desc' ? 'asc' : 'desc');
    setPage(0); // Reset to first page when changing sort order
  };

  const handleDateChange = (date, type) => {
    if (type === 'from') {
      setFromDate(date);
    } else {
      setToDate(date);
    }
    setPage(0); // Reset to first page when changing dates
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleRefresh = () => {
    fetchCalls();
  };

  const handleResetFilters = () => {
    setFromDate(null);
    setToDate(null);
    setSortOrder('desc');
    setSearchInput('');
    setPage(0);
  };

  const handleCopyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(() => {
      alert('Copied to clipboard!');
    });
  };

  const handleChatOpen = (call) => {
    if (call.chat) {
      setSelectedChat(call.chat);
      setSelectedLeadData({
        ...call.leadData,
        RecordUrl: call.RecordUrl // Pass the RecordUrl to selectedLeadData
      });
      setChatModalOpen(true);
    }
  };

  const fetchCalls = async () => {
    try {
      setIsLoading(true);
      
      // Format phone number (remove + prefix)
      const formattedNumber = formatPhoneNumber(selectedNumber);
      
      console.log("About to call API with number:", formattedNumber);
      
      // Call the API endpoint with just the phone number
      const response = await dispatch(fetchIncomingCallsByNumber(formattedNumber));
      
      console.log("API Response in component:", response);
      
      // The data is now consistently returned in response.data
      if (response && response.data) {
        console.log("Call data to use:", response.data.length, "items");
        
        // Set total calls for pagination
        setTotalCalls(response.data.length);
        
        // Directly use the data here for display
        setDirectCallData(response.data);
      }
    } catch (error) {
      console.error('Error fetching incoming calls:', error);
    } finally {
      setIsLoading(false);
    }
  };

  // Fetch calls only when the selected number changes
  useEffect(() => {
    console.log("useEffect triggered with selectedNumber:", selectedNumber);
    if (selectedNumber) {
      console.log("Calling fetchCalls with selectedNumber:", selectedNumber);
      fetchCalls();
    }
  }, [selectedNumber]);

  // Get filtered, sorted, and paginated data for display
  const paginatedData = useMemo(() => {
    if (!directCallData || directCallData.length === 0) return [];
    
    // Filter by search term if provided
    let filteredData = directCallData;
    
    // Apply date filter if selected
    if (fromDate && toDate) {
      const fromDateObj = startOfDay(fromDate);
      const toDateObj = endOfDay(toDate);
      
      filteredData = filteredData.filter(call => {
        if (!call.AnswerTime) return true;
        const callDate = new Date(call.AnswerTime);
        return isWithinInterval(callDate, { start: fromDateObj, end: toDateObj });
      });
    }
    
    // Apply search filter if provided
    if (searchInput) {
      const searchLower = searchInput.toLowerCase();
      filteredData = filteredData.filter(call => {
        return (
          (call.From && call.From.toLowerCase().includes(searchLower)) ||
          (call.To && call.To.toLowerCase().includes(searchLower)) ||
          (call.caller_number && call.caller_number.toLowerCase().includes(searchLower)) ||
          (call.ai_number && call.ai_number.toLowerCase().includes(searchLower))
        );
      });
    }
    
    // Apply sorting
    const sortedData = [...filteredData].sort((a, b) => {
      // Sort by date
      const dateA = a.AnswerTime ? new Date(a.AnswerTime) : new Date(0);
      const dateB = b.AnswerTime ? new Date(b.AnswerTime) : new Date(0);
      
      return sortOrder === 'desc' ? dateB - dateA : dateA - dateB;
    });
    
    // Update total for pagination
    setTotalCalls(sortedData.length);
    
    // Return only the current page of data
    return sortedData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
  }, [directCallData, page, rowsPerPage, searchInput, sortOrder, fromDate, toDate]);

  return (
    <Box className={classes.root}>
      <Typography variant="h5" gutterBottom sx={{ color: '#212529', mb: 3 }}>
        Incoming Calls
      </Typography>

      {/* Filter Section */}
      <Paper elevation={1} sx={{ p: 2, mb: 3, bgcolor: '#ffffff' }}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} sm={6} md={3}>
            <FormControl fullWidth variant="outlined" size="small">
              <InputLabel id="number-select-label">AI Number</InputLabel>
              <Select
                labelId="number-select-label"
                value={selectedNumber}
                onChange={handleNumberChange}
                label="AI Number"
              >
                {callNumbers.map((number) => (
                  <MenuItem key={number} value={number}>
                    {formatPhoneNumber(number)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          
          <Grid item xs={12} sm={6} md={3}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                label="From Date"
                value={fromDate}
                onChange={(date) => handleDateChange(date, 'from')}
                renderInput={(params) => (
                  <TextField 
                    {...params} 
                    fullWidth 
                    size="small"
                  />
                )}
              />
            </LocalizationProvider>
          </Grid>
          
          <Grid item xs={12} sm={6} md={3}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                label="To Date"
                value={toDate}
                onChange={(date) => handleDateChange(date, 'to')}
                renderInput={(params) => (
                  <TextField 
                    {...params} 
                    fullWidth 
                    size="small"
                  />
                )}
              />
            </LocalizationProvider>
          </Grid>
          
          <Grid item xs={12} sm={6} md={3}>
            <Box sx={{ display: 'flex', gap: 1 }}>
              <TextField
                fullWidth
                variant="outlined"
                size="small"
                placeholder="Search calls..."
                onChange={handleSearchChange}
                InputProps={{
                  startAdornment: <SearchIcon sx={{ mr: 1, color: '#6c757d' }} />,
                }}
              />
              
              <Tooltip title={sortOrder === 'desc' ? 'Newest First' : 'Oldest First'}>
                <IconButton 
                  onClick={handleSortOrderChange}
                  sx={{ color: '#495057' }}
                >
                  <SortIcon />
                </IconButton>
              </Tooltip>
              
              <Tooltip title="Refresh">
                <IconButton 
                  onClick={handleRefresh}
                  sx={{ color: '#495057' }}
                >
                  <RefreshIcon />
                </IconButton>
              </Tooltip>
            </Box>
          </Grid>
        </Grid>
        
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
          <Typography variant="body2" sx={{ color: '#6c757d' }}>
            {sortOrder === 'desc' ? 'Sorting: Newest First' : 'Sorting: Oldest First'}
          </Typography>
          
          <Button 
            variant="outlined" 
            size="small"
            onClick={handleResetFilters}
            startIcon={<FilterList />}
            sx={{ color: '#4caf50', borderColor: '#4caf50' }}
          >
            Reset Filters
          </Button>
        </Box>
      </Paper>

      {/* Calls Table */}
      <TableContainer component={Paper} elevation={1} sx={{ bgcolor: '#ffffff' }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell className={classes.tableHeadCell}>Caller</TableCell>
              <TableCell className={classes.tableHeadCell}>Customer Name</TableCell>
              <TableCell className={classes.tableHeadCell}>Date</TableCell>
              <TableCell className={classes.tableHeadCell}>Duration</TableCell>
              <TableCell className={classes.tableHeadCell}>Lead Status</TableCell>
              <TableCell align="center" className={classes.tableHeadCell}>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading ? (
              <TableRow>
                <TableCell colSpan={6} align="center">
                  <CustomLoader />
                </TableCell>
              </TableRow>
            ) : paginatedData.length === 0 ? (
              <TableRow>
                <TableCell colSpan={6} align="center">
                  No calls found
                </TableCell>
              </TableRow>
            ) : (
              paginatedData.map((call) => {
                console.log("Rendering call:", call);
                
                // Parse structuredOutputData if available
                let leadData = null;
                if (call.structuredOutputData) {
                  try {
                    leadData = JSON.parse(call.structuredOutputData);
                  } catch (error) {
                    console.error('Error parsing structuredOutputData:', error);
                  }
                }
                
                return (
                  <TableRow key={call._id || call.CallUUID} className={classes.tableRow}>
                    <TableCell>{formatPhoneNumber(call.From || call.caller_number || call.caller || '')}</TableCell>
                    <TableCell>
                      {call.structuredOutputData ? 
                        (() => {
                          try {
                            const parsed = JSON.parse(call.structuredOutputData);
                            return parsed.name || 'N/A';
                          } catch (e) {
                            return 'N/A';
                          }
                        })() : 
                        call.leadData?.name || 'N/A'
                      }
                    </TableCell>
                    <TableCell>
                      {call.AnswerTime ? format(new Date(call.AnswerTime), 'MMM dd, yyyy HH:mm') : 'N/A'}
                    </TableCell>
                    <TableCell>{call.Duration ? `${call.Duration}s` : 'N/A'}</TableCell>
                    <TableCell>
                      {leadData && (
                        <Chip 
                          label={
                            leadData.hotLead ? 'Hot Lead' : 
                            leadData.warmLead ? 'Warm Lead' : 'Cold Lead'
                          }
                          size="small"
                          sx={{
                            bgcolor: 
                              leadData.hotLead ? '#ffebee' : 
                              leadData.warmLead ? '#fff3e0' : '#e3f2fd',
                            color: 
                              leadData.hotLead ? '#d32f2f' : 
                              leadData.warmLead ? '#ef6c00' : '#1976d2'
                          }}
                        />
                      )}
                    </TableCell>
                    <TableCell align="center">
                      <Tooltip title="View Conversation">
                        <IconButton
                          size="small"
                          onClick={() => handleChatOpen({...call, leadData})}
                          sx={{ 
                            color: '#616161',
                            '&:hover': {
                              color: '#1976d2'
                            },
                            opacity: call.chat ? 1 : 0.5
                          }}
                        >
                          <ChatIcon />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                );
              })
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Pagination */}
      <TablePagination
        component="div"
        count={totalCalls}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        rowsPerPageOptions={[5, 10, 25, 50]}
        sx={{
          bgcolor: '#faf8f6',
          color: '#616161',
          '& .MuiTablePagination-select': {
            color: '#616161'
          }
        }}
      />

      {/* Chat Modal */}
      <Dialog
        open={chatModalOpen}
        onClose={() => setChatModalOpen(false)}
        maxWidth="md"
        fullWidth
        PaperProps={{
          sx: {
            borderRadius: '12px',
            backgroundColor: '#ffffff'
          }
        }}
      >
        <DialogTitle
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            py: 2,
            px: 3,
            color: '#424242',
            borderBottom: '1px solid #f0f0f0'
          }}
        >
          <Typography variant="h6" sx={{ fontWeight: 500 }}>
            Conversation Details
          </Typography>
          <IconButton
            onClick={() => setChatModalOpen(false)}
            size="small"
            sx={{ 
              color: '#757575',
              '&:hover': {
                backgroundColor: 'rgba(0, 0, 0, 0.04)'
              }
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent 
          sx={{ 
            p: 3,
            backgroundColor: '#ffffff',
            minHeight: '400px',
            maxHeight: '80vh',
            overflowY: 'auto'
          }}
        >
          {selectedLeadData && (
            <Paper 
              elevation={0}
              sx={{ 
                mb: 3,
                p: 2.5,
                backgroundColor: '#faf8f6',
                borderRadius: '8px',
                border: '1px solid #f0f0f0'
              }}
            >
              <Typography variant="subtitle1" gutterBottom sx={{ color: '#424242', fontWeight: 500 }}>
                Lead Analysis
              </Typography>
              <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                <Typography variant="subtitle2" sx={{ mr: 2, color: '#616161' }}>Status:</Typography>
                <Chip 
                  label={
                    selectedLeadData.hotLead ? 'Hot Lead' : 
                    selectedLeadData.warmLead ? 'Warm Lead' : 'Cold Lead'
                  }
                  size="small"
                  sx={{
                    bgcolor: 
                      selectedLeadData.hotLead ? '#ffebee' : 
                      selectedLeadData.warmLead ? '#fff3e0' : '#e3f2fd',
                    color: 
                      selectedLeadData.hotLead ? '#d32f2f' : 
                      selectedLeadData.warmLead ? '#ef6c00' : '#1976d2'
                  }}
                />
              </Box>
              {selectedLeadData.name && (
                <Typography variant="body2" gutterBottom sx={{ color: '#616161', lineHeight: 1.6 }}>
                  <strong>Customer Name:</strong> {selectedLeadData.name}
                </Typography>
              )}
              {selectedLeadData.explanation && (
                <Typography variant="body2" gutterBottom sx={{ color: '#616161', lineHeight: 1.6 }}>
                  <strong>Explanation:</strong> {selectedLeadData.explanation}
                </Typography>
              )}
              {selectedLeadData.problem && (
                <Typography variant="body2" gutterBottom sx={{ color: '#616161', lineHeight: 1.6 }}>
                  <strong>Problem:</strong> {selectedLeadData.problem}
                </Typography>
              )}
              {selectedLeadData.detailedSummary && (
                <Typography variant="body2" sx={{ color: '#616161', lineHeight: 1.6 }}>
                  <strong>Summary:</strong> {selectedLeadData.detailedSummary}
                </Typography>
              )}
            </Paper>
          )}
          
          {/* Recording Player */}
          {selectedLeadData && selectedLeadData.RecordUrl && (
            <Paper 
              elevation={0}
              sx={{ 
                mb: 3,
                p: 2.5,
                backgroundColor: '#faf8f6',
                borderRadius: '8px',
                border: '1px solid #f0f0f0'
              }}
            >
              <Typography variant="subtitle1" gutterBottom sx={{ color: '#424242', fontWeight: 500 }}>
                Call Recording
              </Typography>
              <AudioPlayer src={selectedLeadData.RecordUrl} onError={(e) => console.error("Audio error:", e)} />
            </Paper>
          )}
          
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, mt: 3 }}>
            <Typography variant="subtitle1" sx={{ mb: 2, color: '#424242', fontWeight: 500 }}>
              Chat Transcript
            </Typography>
            
            {selectedChat.split('|').map((message, index) => {
              if (!message.trim()) return null;
              
              const isHuman = message.trim().startsWith('human:');
              const isAI = message.trim().startsWith('AI:');
              
              if (!isHuman && !isAI) return null;
              
              return (
                <Box
                  key={index}
                  sx={{
                    display: 'flex',
                    justifyContent: isHuman ? 'flex-end' : 'flex-start',
                    width: '100%'
                  }}
                >
                  <Paper
                    elevation={0}
                    sx={{
                      p: 2,
                      maxWidth: '75%',
                      backgroundColor: isHuman ? '#f3f6f9' : '#faf8f6',
                      borderRadius: '12px',
                      border: '1px solid #f0f0f0'
                    }}
                  >
                    <Typography 
                      variant="body2" 
                      sx={{ 
                        color: '#616161',
                        lineHeight: 1.6,
                        whiteSpace: 'pre-wrap'
                      }}
                    >
                      {message.trim().replace(/^(human:|AI:)/, '')}
                    </Typography>
                  </Paper>
                </Box>
              );
            })}
          </Box>
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default React.memo(IncomingCalls);

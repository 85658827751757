import React, { useState, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Box,
  Typography,
  IconButton,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  CircularProgress,
  Paper,
  Divider,
  Chip,
  Stack,
  Tooltip,
  Grid
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import CampaignIcon from '@mui/icons-material/Campaign';
import WarningIcon from '@mui/icons-material/Warning';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import LocalFireDepartmentIcon from '@mui/icons-material/LocalFireDepartment';
import WhatshotIcon from '@mui/icons-material/Whatshot';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

import { createCampaign, getCampaigns, getAssistants } from '../../actions/campaignActions';
import { getContactLists } from '../../actions/contactActions';
import { fetchClientInfo } from '../../services/apiService';

const formControlStyles = {
  width: '100%',
  marginBottom: 2,
  '& .MuiInputLabel-root': {
    color: '#6B7280',
  },
  '& .MuiInputBase-root': {
    color: '#6B7280',
  },
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: '#6B7280',
  },
  '& .MuiSelect-select': {
    color: '#6B7280',
  },
  '& .MuiMenuItem-root': {
    color: '#6B7280',
  },
};

const AlertBox = ({ icon: Icon, title, message, buttonText, onButtonClick, showButton = true }) => (
  <Paper 
    elevation={3} 
    sx={{ 
      p: 3, 
      mb: 3, 
      backgroundColor: 'white',
      borderRadius: 2,
      textAlign: 'center'
    }}
  >
    <Icon sx={{ 
      fontSize: 48, 
      mb: 2,
      color: '#2196f3'
    }} />
    <Typography variant="h6" sx={{ mb: 1, color: '#333' }}>
      {title}
    </Typography>
    <Typography sx={{ mb: 2, color: '#666' }}>
      {message}
    </Typography>
    {showButton && (
      <Button
        variant="contained"
        onClick={onButtonClick}
        sx={{
          bgcolor: '#2196f3',
          color: 'white',
          '&:hover': {
            bgcolor: '#1976d2',
          }
        }}
      >
        {buttonText}
      </Button>
    )}
  </Paper>
);

export default function StartNewCampaignModal({ open, onClose }) {
  const dispatch = useDispatch();
  const auth = JSON.parse(localStorage.getItem('auth') || '{}');

  const [formData, setFormData] = useState({
    campaignName: '',
    listId: '',
    fromNumber: '',
    assistantId: '',
    wssUrl: '',
    leadSegmentation: {
      hotLeads: [
        { condition: 'strongly interested' }
      ],
      warmLeads: [
        { condition: 'somewhat interested' }
      ]
    }
  });
  const [assistants, setAssistants] = useState([]);
  const [lists, setLists] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [clientInfo, setClientInfo] = useState(null);
  const [showBalanceCheck, setShowBalanceCheck] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);

  const campaignNameRef = useRef(null);

  useEffect(() => {
    if (open && campaignNameRef.current) {
      setTimeout(() => {
        campaignNameRef.current.focus();
      }, 300); // Small delay to ensure modal is fully rendered
    }
  }, [open]);

  const handleInputChange = (field) => (event) => {
    const value = event.target.value;
    setFormData(prev => ({ ...prev, [field]: value }));

    // Set wssUrl when assistant is selected
    if (field === 'assistantId') {
      const selectedAssistant = assistants.find(a => a._id === value);
      if (selectedAssistant?.agent_name) {
        setFormData(prev => ({ ...prev, wssUrl: selectedAssistant.agent_name }));
      } else {
        // If no agent_name in assistant, use default
        setFormData(prev => ({ 
          ...prev, 
          wssUrl: 'null'
        }));
      }
    }
  };

  const handleLeadConditionChange = (leadType, index) => (event) => {
    const value = event.target.value;
    setFormData(prev => ({
      ...prev,
      leadSegmentation: {
        ...prev.leadSegmentation,
        [leadType]: prev.leadSegmentation[leadType].map((item, i) => 
          i === index ? { condition: value } : item
        )
      }
    }));
  };

  const addLeadCondition = (leadType) => {
    if (formData.leadSegmentation[leadType].length < 5) {
      setFormData(prev => ({
        ...prev,
        leadSegmentation: {
          ...prev.leadSegmentation,
          [leadType]: [...prev.leadSegmentation[leadType], { condition: '' }]
        }
      }));
    }
  };

  const removeLeadCondition = (leadType, index) => {
    if (formData.leadSegmentation[leadType].length > 1) {
      setFormData(prev => ({
        ...prev,
        leadSegmentation: {
          ...prev.leadSegmentation,
          [leadType]: prev.leadSegmentation[leadType].filter((_, i) => i !== index)
        }
      }));
    }
  };

  // Fetch assistants, lists and client info when modal opens
  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        setError(null);

        // Fetch client info first
        const clientResponse = await fetchClientInfo(auth._id);
        setClientInfo(clientResponse);
        setShowBalanceCheck(true);

        // Continue with other fetches
        const assistantsResponse = await dispatch(getAssistants());
        if (assistantsResponse?.data) {
          const filteredAssistants = assistantsResponse.data.filter(
            (assistant) => assistant.wssUrl
          );
          setAssistants(filteredAssistants);
        }

        const listsResponse = await dispatch(getContactLists());
        if (listsResponse?.data) {
          setLists(listsResponse.data);
        }
      } catch (err) {
        console.error('Error fetching data:', err);
        setError('Failed to load required data. Please try again.');
      } finally {
        setIsLoading(false);
      }
    };

    if (open) {
      fetchData();
    }
  }, [dispatch, open, auth._id]);

  const handleStartCampaign = async () => {
    try {
      setIsLoading(true);
      setError(null);

      // First check client info
      const clientResponse = await fetchClientInfo(auth._id);
      setClientInfo(clientResponse);

      if (clientResponse.activeCampId) {
        setError('Cannot start a new campaign while another campaign is active.');
        setIsLoading(false);
        return;
      }

      if (!clientResponse.availableBalance || clientResponse.availableBalance < 50) {
        setError('Insufficient balance. Please contact Markaible administrative.');
        setIsLoading(false);
        return;
      }

      // Show confirmation dialog
      setShowConfirmation(true);
      setIsLoading(false);
    } catch (err) {
      console.error('Error checking campaign conditions:', err);
      setError(err.message || 'Failed to check campaign conditions. Please try again.');
      setIsLoading(false);
    }
  };

  const handleConfirmStart = async () => {
    try {
      setIsLoading(true);
      setError(null);

      // Filter out empty conditions
      const hotCond = formData.leadSegmentation.hotLeads
        .filter(item => item.condition.trim() !== '')
        .map(item => item.condition);
      
      const warmCond = formData.leadSegmentation.warmLeads
        .filter(item => item.condition.trim() !== '')
        .map(item => item.condition);

      // Validate that we have at least one non-empty condition for each lead type
      if (hotCond.length === 0) {
        setError('Please add at least one condition for Hot Leads');
        setIsLoading(false);
        return;
      }

      if (warmCond.length === 0) {
        setError('Please add at least one condition for Warm Leads');
        setIsLoading(false);
        return;
      }

      const campaignData = {
        ...formData,
        clientId: auth._id,
        wssUrl: formData.wssUrl || 'null',
        hotCond,
        warmCond
      };

      await dispatch(createCampaign(campaignData));
      await dispatch(getCampaigns());
      setShowConfirmation(false);
      onClose();
    } catch (err) {
      console.error('Error starting campaign:', err);
      setError(err.message || 'Failed to start campaign. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleContactAdmin = () => {
    window.location.href = 'mailto:dev@markaible.com';
  };

  const isFormValid = () => {
    // Check if basic form fields are filled
    const basicFieldsValid = formData.campaignName && 
           formData.listId && 
           formData.fromNumber && 
           formData.wssUrl;
    
    // Check if at least one hot lead condition is valid
    const hasValidHotCondition = formData.leadSegmentation.hotLeads.some(
      item => item.condition.trim() !== ''
    );
    
    // Check if at least one warm lead condition is valid
    const hasValidWarmCondition = formData.leadSegmentation.warmLeads.some(
      item => item.condition.trim() !== ''
    );
    
    return basicFieldsValid && hasValidHotCondition && hasValidWarmCondition;  
  };

  if (!showBalanceCheck) {
    return null;
  }

  // Show alert boxes if there are conditions to check
  if (clientInfo?.activeCampId || !clientInfo?.availableBalance || clientInfo?.availableBalance < 50) {
    return (
      <Dialog
        open={open}
        onClose={onClose}
        maxWidth="sm"
        fullWidth
        PaperProps={{
          style: {
            backgroundColor: '#f5f5f5',
            padding: '20px'
          },
        }}
      >
        <DialogTitle>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Typography variant="h5" sx={{ color: '#333' }}>Campaign Status Check</Typography>
            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>

        <DialogContent>
          {/* Active Campaign Alert */}
          {clientInfo?.activeCampId && (
            <AlertBox
              icon={CampaignIcon}
              title="Active Campaign Running"
              message="You already have an active campaign. Please wait for it to complete before starting a new one."
              showButton={false}
            />
          )}

          {/* Available Balance Alert */}
          <AlertBox
            icon={AccountBalanceWalletIcon}
            title="Current Balance"
            message={`Your available balance is ${clientInfo?.availableBalance || 0}`}
            showButton={false}
          />

          {/* Minimum Balance Alert */}
          {(!clientInfo?.availableBalance || clientInfo?.availableBalance < 50) && (
            <AlertBox
              icon={WarningIcon}
              title="Insufficient Balance"
              message="A minimum balance of 50 is required to start a campaign. Please contact Markaible administrative to add funds."
              buttonText="Contact Admin"
              onButtonClick={handleContactAdmin}
            />
          )}
        </DialogContent>

        <DialogActions>
          <Button onClick={onClose} variant="outlined">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  // Show the regular campaign form if all conditions are met
  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        maxWidth="md"
        fullWidth
        PaperProps={{
          style: {
            backgroundColor: '#FAF9F6',
          },
        }}
      >
        <DialogTitle>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Typography sx={{ color: '#6B7280' }}>Start Campaign</Typography>
            <IconButton onClick={onClose} sx={{ color: '#6B7280' }}>
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>

        <DialogContent>
          <Box component="form" sx={{ mt: 2 }}>
            {/* Lead Segmentation */}
            <Box sx={{ mb: 4 }}>
              <Divider sx={{ mb: 3 }}>
                <Chip 
                  label="Lead Segmentation" 
                  sx={{ 
                    px: 2, 
                    backgroundColor: '#f0f0f0', 
                    color: '#333',
                    fontWeight: 'bold',
                    '& .MuiChip-label': { px: 2 }
                  }} 
                />
              </Divider>
              
              <Typography sx={{ mb: 3, color: '#666', fontSize: '0.9rem' }}>
                Define conditions to categorize leads as Hot or Warm. Each lead will be evaluated against these conditions.
                <br />
                At least one condition must be defined for each lead type.
              </Typography>
              
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <Box sx={{ 
                    p: 2, 
                    border: '1px solid #ffcccc', 
                    borderRadius: 2, 
                    bgcolor: '#fff9f9',
                    boxShadow: '0 2px 4px rgba(0,0,0,0.05)',
                    height: '100%'
                  }}>
                    <Typography variant="h6" sx={{ 
                      mb: 2, 
                      color: '#d32f2f', 
                      display: 'flex', 
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      fontWeight: 'bold'
                    }}>
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <LocalFireDepartmentIcon sx={{ mr: 1 }} /> Hot Leads
                        <Tooltip title="Hot leads are highly interested prospects likely to convert quickly">
                          <IconButton size="small" sx={{ ml: 1 }}>
                            <HelpOutlineIcon fontSize="small" color="action" />
                          </IconButton>
                        </Tooltip>
                      </Box>
                      {formData.leadSegmentation.hotLeads.length < 5 && (
                        <Tooltip title="Add condition">
                          <IconButton 
                            size="small" 
                            onClick={() => addLeadCondition('hotLeads')}
                            sx={{ color: '#d32f2f' }}
                          >
                            <AddIcon />
                          </IconButton>
                        </Tooltip>
                      )}
                    </Typography>
                    
                    {formData.leadSegmentation.hotLeads.map((item, index) => (
                      <Box key={index} sx={{ mb: 2, display: 'flex', alignItems: 'center' }}>
                        <TextField
                          label={`Condition ${index + 1}`}
                          variant="outlined"
                          fullWidth
                          value={item.condition}
                          onChange={handleLeadConditionChange('hotLeads', index)}
                          placeholder="Enter a condition phrase"
                          sx={{ 
                            mb: 1,
                            '& .MuiOutlinedInput-root': {
                              '&.Mui-focused fieldset': {
                                borderColor: '#d32f2f',
                                borderWidth: 2,
                              },
                            },
                          }}
                          InputLabelProps={{ 
                            style: { color: '#6B7280' },
                            shrink: !!item.condition,
                          }}
                          InputProps={{ style: { color: '#6B7280' } }}
                          error={item.condition.trim() === ''}
                          helperText={item.condition.trim() === '' ? 'Condition cannot be empty' : ''}
                        />
                        {formData.leadSegmentation.hotLeads.length > 1 && (
                          <IconButton 
                            size="small" 
                            onClick={() => removeLeadCondition('hotLeads', index)}
                            sx={{ ml: 1, color: '#d32f2f' }}
                          >
                            <DeleteIcon fontSize="small" />
                          </IconButton>
                        )}
                      </Box>
                    ))}
                    <Typography variant="caption" sx={{ color: '#666', display: 'block', mt: 1 }}>
                      Enter phrases that indicate a hot lead (e.g., "strongly interested", "ready to buy")
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Box sx={{ 
                    p: 2, 
                    border: '1px solid #ccd9ff', 
                    borderRadius: 2, 
                    bgcolor: '#f9f9ff',
                    boxShadow: '0 2px 4px rgba(0,0,0,0.05)',
                    height: '100%'
                  }}>
                    <Typography variant="h6" sx={{ 
                      mb: 2, 
                      color: '#1976d2', 
                      display: 'flex', 
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      fontWeight: 'bold'
                    }}>
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <WhatshotIcon sx={{ mr: 1 }} /> Warm Leads
                        <Tooltip title="Warm leads show interest but may need more nurturing before conversion">
                          <IconButton size="small" sx={{ ml: 1 }}>
                            <HelpOutlineIcon fontSize="small" color="action" />
                          </IconButton>
                        </Tooltip>
                      </Box>
                      {formData.leadSegmentation.warmLeads.length < 5 && (
                        <Tooltip title="Add condition">
                          <IconButton 
                            size="small" 
                            onClick={() => addLeadCondition('warmLeads')}
                            sx={{ color: '#1976d2' }}
                          >
                            <AddIcon />
                          </IconButton>
                        </Tooltip>
                      )}
                    </Typography>
                    
                    {formData.leadSegmentation.warmLeads.map((item, index) => (
                      <Box key={index} sx={{ mb: 2, display: 'flex', alignItems: 'center' }}>
                        <TextField
                          label={`Condition ${index + 1}`}
                          variant="outlined"
                          fullWidth
                          value={item.condition}
                          onChange={handleLeadConditionChange('warmLeads', index)}
                          placeholder="Enter a condition phrase"
                          sx={{ 
                            mb: 1,
                            '& .MuiOutlinedInput-root': {
                              '&.Mui-focused fieldset': {
                                borderColor: '#1976d2',
                                borderWidth: 2,
                              },
                            },
                          }}
                          InputLabelProps={{ 
                            style: { color: '#6B7280' },
                            shrink: !!item.condition,
                          }}
                          InputProps={{ style: { color: '#6B7280' } }}
                          error={item.condition.trim() === ''}
                          helperText={item.condition.trim() === '' ? 'Condition cannot be empty' : ''}
                        />
                        {formData.leadSegmentation.warmLeads.length > 1 && (
                          <IconButton 
                            size="small" 
                            onClick={() => removeLeadCondition('warmLeads', index)}
                            sx={{ ml: 1, color: '#1976d2' }}
                          >
                            <DeleteIcon fontSize="small" />
                          </IconButton>
                        )}
                      </Box>
                    ))}
                    <Typography variant="caption" sx={{ color: '#666', display: 'block', mt: 1 }}>
                      Enter phrases that indicate a warm lead (e.g., "somewhat interested", "considering options")
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Box>

            {/* Campaign Settings */}
            <Box sx={{ mt: 4 }}>
              <Divider sx={{ mb: 3 }}>
                <Chip 
                  label="Campaign Settings" 
                  sx={{ 
                    px: 2, 
                    backgroundColor: '#f0f0f0', 
                    color: '#333',
                    fontWeight: 'bold',
                    '& .MuiChip-label': { px: 2 }
                  }} 
                />
              </Divider>
              
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  {/* Campaign Name */}
                  <TextField
                    inputRef={campaignNameRef}
                    label="Campaign Name"
                    variant="outlined"
                    fullWidth
                    value={formData.campaignName}
                    onChange={handleInputChange('campaignName')}
                    sx={{ 
                      mb: 3,
                      '& .MuiOutlinedInput-root': {
                        '&.Mui-focused fieldset': {
                          borderColor: '#2196f3',
                          borderWidth: 2,
                        },
                      },
                    }}
                    InputLabelProps={{ 
                      style: { color: '#6B7280' },
                      shrink: !!formData.campaignName,
                    }}
                    InputProps={{ 
                      style: { color: '#6B7280' },
                    }}
                    autoFocus
                    placeholder="Enter a name for your campaign"
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  {/* Assistant Selection */}
                  <FormControl fullWidth variant="outlined" sx={{ ...formControlStyles, mb: 3 }}>
                    <InputLabel>Select Assistant</InputLabel>
                    <Select
                      value={formData.wssUrl}
                      onChange={handleInputChange('wssUrl')}
                      label="Select Assistant"
                      MenuProps={{
                        PaperProps: {
                          sx: {
                            bgcolor: '#faf8f6',
                            '& .MuiMenuItem-root': {
                              color: '#616161'
                            }
                          }
                        }
                      }}
                    >
                      {assistants.map((assistant) => (
                        <MenuItem key={assistant._id} value={assistant.wssUrl} sx={{ color: '#616161' }}>
                          {assistant.agent_name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  {/* Contact List Selection */}
                  <FormControl fullWidth variant="outlined" sx={{ ...formControlStyles, mb: 3 }}>
                    <InputLabel>Select Contact List</InputLabel>
                    <Select
                      value={formData.listId}
                      onChange={handleInputChange('listId')}
                      label="Select Contact List"
                      MenuProps={{
                        PaperProps: {
                          sx: {
                            bgcolor: '#faf8f6',
                            '& .MuiMenuItem-root': {
                              color: '#616161'
                            }
                          }
                        }
                      }}
                    >
                      {lists.map((list) => (
                        <MenuItem key={list._id} value={list._id} sx={{ color: '#616161' }}>
                          {list.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  {/* Caller Number Selection */}
                  <FormControl fullWidth variant="outlined" sx={formControlStyles}>
                    <InputLabel>Select Caller Number</InputLabel>
                    <Select
                      value={formData.fromNumber}
                      onChange={handleInputChange('fromNumber')}
                      label="Select Caller Number"
                      MenuProps={{
                        PaperProps: {
                          sx: {
                            bgcolor: '#faf8f6',
                            '& .MuiMenuItem-root': {
                              color: '#616161'
                            }
                          }
                        }
                      }}
                    >
                      {auth?.callerNumbers?.map((number) => (
                        <MenuItem key={number} value={number} sx={{ color: '#616161' }}>
                          {number}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Box>

            {error && (
              <Typography color="error" sx={{ mt: 2 }}>
                {error}
              </Typography>
            )}
          </Box>
        </DialogContent>

        <DialogActions sx={{ p: 3 }}>
          <Button
            variant="contained"
            onClick={handleStartCampaign}
            disabled={isLoading || !isFormValid()}
            sx={{
              bgcolor: '#2E7D32',
              color: 'white',
              '&:hover': {
                bgcolor: '#1B5E20',
              },
              '&:disabled': {
                bgcolor: '#A5D6A7',
              }
            }}
          >
            {isLoading ? <CircularProgress size={24} color="inherit" /> : 'Start Campaign'}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Confirmation Dialog */}
      <Dialog
        open={showConfirmation}
        onClose={() => setShowConfirmation(false)}
        maxWidth="md"
        fullWidth
        PaperProps={{
          style: {
            backgroundColor: '#ffffff',
          },
        }}
      >
        <Box sx={{ p: 3, textAlign: 'center' }}>
          <AccountBalanceWalletIcon 
            sx={{ 
              fontSize: 64, 
              color: '#2196f3',
              mb: 2
            }} 
          />
          
          <Typography variant="h5" sx={{ mb: 2, color: '#333' }}>
            Confirm Campaign Start
          </Typography>

          <Paper 
            elevation={0}
            sx={{ 
              p: 3, 
              mb: 3, 
              backgroundColor: '#f8f9fa',
              borderRadius: 2
            }}
          >
            <Typography variant="h6" sx={{ mb: 1, color: '#666' }}>
              Available Balance
            </Typography>
            <Typography variant="h4" sx={{ color: '#2196f3', fontWeight: 'bold' }}>
              {clientInfo?.availableBalance || 0}
            </Typography>
          </Paper>

          <Typography sx={{ mb: 3, color: '#666' }}>
            Are you sure you want to start this campaign?
            <br />
            Campaign Name: <strong>{formData.campaignName}</strong>
          </Typography>
          
          <Box sx={{ mb: 3 }}>
            <Typography variant="subtitle1" sx={{ mb: 1, color: '#333', fontWeight: 'bold' }}>
              Lead Segmentation Summary:
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Paper elevation={0} sx={{ p: 2, bgcolor: '#fff9f9', borderRadius: 2 }}>
                  <Typography variant="subtitle2" sx={{ color: '#d32f2f', display: 'flex', alignItems: 'center' }}>
                    <LocalFireDepartmentIcon sx={{ mr: 1, fontSize: 16 }} /> Hot Leads: {formData.leadSegmentation.hotLeads.filter(item => item.condition.trim() !== '').length} conditions
                  </Typography>
                </Paper>
              </Grid>
              <Grid item xs={6}>
                <Paper elevation={0} sx={{ p: 2, bgcolor: '#f9f9ff', borderRadius: 2 }}>
                  <Typography variant="subtitle2" sx={{ color: '#1976d2', display: 'flex', alignItems: 'center' }}>
                    <WhatshotIcon sx={{ mr: 1, fontSize: 16 }} /> Warm Leads: {formData.leadSegmentation.warmLeads.filter(item => item.condition.trim() !== '').length} conditions
                  </Typography>
                </Paper>
              </Grid>
            </Grid>
          </Box>

          <Box sx={{ display: 'flex', justifyContent: 'center', gap: 2 }}>
            <Button
              variant="outlined"
              onClick={() => setShowConfirmation(false)}
              sx={{ minWidth: 120 }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              onClick={handleConfirmStart}
              disabled={isLoading}
              sx={{
                minWidth: 120,
                bgcolor: '#2196f3',
                '&:hover': {
                  bgcolor: '#1976d2',
                }
              }}
            >
              {isLoading ? <CircularProgress size={24} color="inherit" /> : 'Start Campaign'}
            </Button>
          </Box>
        </Box>
      </Dialog>
    </>
  );
}
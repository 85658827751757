import React, { useState, useEffect, useRef, useCallback } from 'react';
import {
  Box,
  Button,
  TextField,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Select,
  MenuItem,
  InputLabel,
  Typography,
  IconButton,
  Grid,
  Alert
} from '@mui/material';
import { ArrowBack as ArrowBackIcon, PlayArrow, Stop } from '@mui/icons-material';
import AudioPlayer from '../AudioPlayer';

const voiceSamples = [
  { 
    id: 'akshay',
    name: 'Akshay',
    url: '/voices_sample/voice_preview_akshay.mp3',
    previewText: 'Professional Male Voice',
    language: 'english'
  },
  { 
    id: 'bhoomi',
    name: 'Bhoomi',
    url: '/voices_sample/voice_preview_bhoomi2.mp3',
    previewText: 'Professional Female Voice',
    language: 'english'
  },
  { 
    id: 'devi',
    name: 'Devi',
    url: '/voices_sample/voice_preview_devi - clear hindi pronunciation.mp3',
    previewText: 'Clear Hindi Pronunciation',
    language: 'hindi'
  },
  { 
    id: 'drew',
    name: 'Drew',
    url: '/voices_sample/voice_preview_drew.mp3',
    previewText: 'Casual Male Voice',
    language: 'english'
  },
  { 
    id: 'manav',
    name: 'Manav',
    url: '/voices_sample/voice_preview_manav -  husky, conversational voice.mp3',
    previewText: 'Husky, Conversational Voice',
    language: 'hinglish'
  },
  { 
    id: 'monika',
    name: 'Monika Sogam',
    url: '/voices_sample/voice_preview_monika sogam.mp3',
    previewText: 'Professional Female Voice',
    language: 'english'
  },
  { 
    id: 'rachel',
    name: 'Rachel',
    url: '/voices_sample/voice_preview_rachel.mp3',
    previewText: 'Professional Female Voice',
    language: 'english'
  },
  { 
    id: 'ruhaan',
    name: 'Ruhaan',
    url: '/voices_sample/voice_preview_ruhaan - clean hindi narration voice.mp3',
    previewText: 'Clean Hindi Narration',
    language: 'hindi'
  },
  { 
    id: 'suhaan',
    name: 'Suhaan',
    url: '/voices_sample/voice_preview_suhaan - delhi guy.mp3',
    previewText: 'Delhi Guy Voice',
    language: 'hinglish'
  },
  { 
    id: 'yspl',
    name: 'YSPL',
    url: '/voices_sample/voice_preview_yspl.mp3',
    previewText: 'Professional Voice',
    language: 'english'
  }
];

const aiModels = [
  { id: 'openai', name: 'OpenAI', description: 'Conversational and nice' },
  { id: 'deepseek', name: 'Deepseek', description: 'Intelligent and fast' },
];

const formLabelStyle = {
  color: '#1a1a1a',
  fontWeight: 500,
  mb: 1,
  fontFamily: 'Raleway'
};

const selectStyle = {
  '& .MuiSelect-select': {
    backgroundColor: '#faf8f6',
    color: '#5f6368',
  },
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: 'rgba(95, 99, 104, 0.2)',
  },
  '&:hover .MuiOutlinedInput-notchedOutline': {
    borderColor: 'rgba(95, 99, 104, 0.3)',
  },
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: '#5f6368',
  },
};

const CreateAIScratchForm = ({ onBack, onClose }) => {
  const [formData, setFormData] = useState({
    agentName: '',
    welcomeMessage: '',
    selectedVoice: '',
    aiName: '',
    gender: 'female',
    aiModel: '',
    callTimeout: 300, // 5 minutes default
    script: '',
    language: 'english',
    callType: 'inbound'
  });

  const [audioError, setAudioError] = useState(false);
  const [filteredVoices, setFilteredVoices] = useState(voiceSamples);
  const [debouncedVoice, setDebouncedVoice] = useState('');
  const debounceTimeout = useRef(null);

  useEffect(() => {
    // Cleanup timeout on unmount
    return () => {
      if (debounceTimeout.current) {
        clearTimeout(debounceTimeout.current);
      }
    };
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
    if (name === 'selectedVoice') {
      setAudioError(false);
    }
    if (name === 'language') {
      setFilteredVoices(
        voiceSamples.filter(voice => 
          value === 'all' ? true : voice.language === value
        )
      );
      setFormData(prev => ({
        ...prev,
        selectedVoice: ''
      }));
      setDebouncedVoice('');
    }
  };

  const handleVoiceSelect = useCallback((voiceId) => {
    setFormData(prevState => ({
      ...prevState,
      selectedVoice: voiceId
    }));
    setAudioError(false);

    // Clear any existing timeout
    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current);
    }

    // Set a new timeout to update the debounced voice
    debounceTimeout.current = setTimeout(() => {
      setDebouncedVoice(voiceId);
    }, 300); // 300ms debounce delay
  }, []);

  const handleAudioError = () => {
    setAudioError(true);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch('/api/create-ai', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });
      
      if (response.ok) {
        console.log('AI prompt created successfully');
        onClose();
      }
    } catch (error) {
      console.error('Error creating AI:', error);
    }
  };

  return (
    <Box component="form" onSubmit={handleSubmit} sx={{ mt: 2 }}>
      <Box sx={{ mb: 3, display: 'flex', alignItems: 'center' }}>
        <IconButton 
          onClick={onBack} 
          size="small" 
          sx={{ 
            mr: 1,
            color: '#5f6368',
            '&:hover': {
              backgroundColor: 'rgba(95, 99, 104, 0.08)'
            }
          }}
        >
          <ArrowBackIcon />
        </IconButton>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <Typography variant="h6" color="text.primary" sx={{ fontFamily: 'Raleway', fontWeight: 600 }}>
            Create AI from Scratch
          </Typography>
        </Box>
      </Box>

      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Typography sx={formLabelStyle}>Agent Name</Typography>
          <TextField
            fullWidth
            name="agentName"
            value={formData.agentName}
            onChange={handleInputChange}
            required
            sx={{
              '& .MuiOutlinedInput-root': {
                backgroundColor: '#faf8f6',
                '& fieldset': {
                  borderColor: 'rgba(95, 99, 104, 0.2)',
                },
                '&:hover fieldset': {
                  borderColor: 'rgba(95, 99, 104, 0.3)',
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#5f6368',
                },
              },
              '& .MuiInputBase-input': {
                color: '#5f6368',
              },
            }}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <Typography sx={formLabelStyle}>AI Name</Typography>
          <TextField
            fullWidth
            name="aiName"
            value={formData.aiName}
            onChange={handleInputChange}
            required
            sx={{
              '& .MuiOutlinedInput-root': {
                backgroundColor: '#faf8f6',
                '& fieldset': {
                  borderColor: 'rgba(95, 99, 104, 0.2)',
                },
                '&:hover fieldset': {
                  borderColor: 'rgba(95, 99, 104, 0.3)',
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#5f6368',
                },
              },
              '& .MuiInputBase-input': {
                color: '#5f6368',
              },
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <Typography sx={formLabelStyle}>Welcome Message</Typography>
          <TextField
            fullWidth
            name="welcomeMessage"
            value={formData.welcomeMessage}
            onChange={handleInputChange}
            multiline
            rows={2}
            required
            sx={{
              '& .MuiOutlinedInput-root': {
                backgroundColor: '#faf8f6',
                '& fieldset': {
                  borderColor: 'rgba(95, 99, 104, 0.2)',
                },
                '&:hover fieldset': {
                  borderColor: 'rgba(95, 99, 104, 0.3)',
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#5f6368',
                },
              },
              '& .MuiInputBase-input': {
                color: '#5f6368',
              },
            }}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <Typography sx={formLabelStyle}>Gender</Typography>
          <RadioGroup
            row
            name="gender"
            value={formData.gender}
            onChange={handleInputChange}
          >
            <FormControlLabel 
              value="female" 
              control={
                <Radio 
                  sx={{
                    color: '#5f6368',
                    '&.Mui-checked': {
                      color: '#5f6368',
                    },
                  }}
                />
              } 
              label={<Typography sx={{ color: '#5f6368' }}>Female</Typography>}
            />
            <FormControlLabel 
              value="male" 
              control={
                <Radio 
                  sx={{
                    color: '#5f6368',
                    '&.Mui-checked': {
                      color: '#5f6368',
                    },
                  }}
                />
              } 
              label={<Typography sx={{ color: '#5f6368' }}>Male</Typography>}
            />
          </RadioGroup>
        </Grid>

        <Grid item xs={12} md={6}>
          <Typography sx={formLabelStyle}>Call Type</Typography>
          <RadioGroup
            row
            name="callType"
            value={formData.callType}
            onChange={handleInputChange}
          >
            <FormControlLabel 
              value="inbound" 
              control={
                <Radio 
                  sx={{
                    color: '#5f6368',
                    '&.Mui-checked': {
                      color: '#5f6368',
                    },
                  }}
                />
              } 
              label={<Typography sx={{ color: '#5f6368' }}>Inbound</Typography>}
            />
            <FormControlLabel 
              value="outbound" 
              control={
                <Radio 
                  sx={{
                    color: '#5f6368',
                    '&.Mui-checked': {
                      color: '#5f6368',
                    },
                  }}
                />
              } 
              label={<Typography sx={{ color: '#5f6368' }}>Outbound</Typography>}
            />
          </RadioGroup>
        </Grid>

        <Grid item xs={12} md={6}>
          <Typography sx={formLabelStyle}>Language</Typography>
          <FormControl fullWidth>
            <Select
              value={formData.language}
              name="language"
              onChange={handleInputChange}
            >
              <MenuItem value="all">All Languages</MenuItem>
              <MenuItem value="english">English</MenuItem>
              <MenuItem value="hindi">Hindi</MenuItem>
              <MenuItem value="hinglish">Hinglish</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} md={6}>
          <Typography sx={formLabelStyle}>Voice Sample</Typography>
          <FormControl fullWidth>
            <Select
              value={formData.selectedVoice}
              name="selectedVoice"
              onChange={(e) => handleVoiceSelect(e.target.value)}
              required
              sx={selectStyle}
            >
              {filteredVoices.map((voice) => (
                <MenuItem 
                  key={voice.id} 
                  value={voice.id}
                  sx={{
                    backgroundColor: '#faf8f6',
                    color: '#5f6368',
                    '&:hover': {
                      backgroundColor: '#f0ede9',
                    },
                    '&.Mui-selected': {
                      backgroundColor: '#f0ede9',
                      '&:hover': {
                        backgroundColor: '#e8e5e1',
                      },
                    },
                  }}
                >
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center' }}>
                    <span>{voice.name}</span>
                  </Box>
                </MenuItem>
              ))}
            </Select>
            {debouncedVoice && !audioError && (
              <Box sx={{ mt: 2 }}>
                <AudioPlayer 
                  src={voiceSamples.find(v => v.id === debouncedVoice)?.url}
                  onError={handleAudioError}
                />
              </Box>
            )}
            {audioError && (
              <Alert severity="error" sx={{ mt: 1 }}>
                Unable to load voice sample. Please try again later.
              </Alert>
            )}
          </FormControl>
        </Grid>

        <Grid item xs={12} md={6}>
          <Typography sx={formLabelStyle}>AI Model</Typography>
          <FormControl fullWidth>
            <Select
              name="aiModel"
              value={formData.aiModel}
              onChange={handleInputChange}
              required
              sx={selectStyle}
            >
              {aiModels.map((model) => (
                <MenuItem 
                  key={model.id} 
                  value={model.id}
                  sx={{
                    backgroundColor: '#faf8f6',
                    color: '#5f6368',
                    '&:hover': {
                      backgroundColor: '#f0ede9',
                    },
                    '&.Mui-selected': {
                      backgroundColor: '#f0ede9',
                      '&:hover': {
                        backgroundColor: '#e8e5e1',
                      },
                    },
                  }}
                >
                  <Box>
                    <Typography variant="subtitle2">{model.name}</Typography>
                    <Typography variant="caption" color="text.secondary">
                      {model.description}
                    </Typography>
                  </Box>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} md={6}>
          <Typography sx={formLabelStyle}>Call End Timeout (seconds)</Typography>
          <TextField
            fullWidth
            type="number"
            name="callTimeout"
            value={formData.callTimeout}
            onChange={handleInputChange}
            inputProps={{ min: 60, max: 3600 }}
            required
            sx={{
              '& .MuiOutlinedInput-root': {
                backgroundColor: '#faf8f6',
                '& fieldset': {
                  borderColor: 'rgba(95, 99, 104, 0.2)',
                },
                '&:hover fieldset': {
                  borderColor: 'rgba(95, 99, 104, 0.3)',
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#5f6368',
                },
              },
              '& .MuiInputBase-input': {
                color: '#5f6368',
              },
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <Typography sx={formLabelStyle}>Script and Additional Information</Typography>
          <TextField
            fullWidth
            name="script"
            value={formData.script}
            onChange={handleInputChange}
            multiline
            rows={8}
            placeholder="Paste the script and other relevant information here..."
            required
            sx={{
              '& .MuiOutlinedInput-root': {
                backgroundColor: '#faf8f6',
                '& fieldset': {
                  borderColor: 'rgba(95, 99, 104, 0.2)',
                },
                '&:hover fieldset': {
                  borderColor: 'rgba(95, 99, 104, 0.3)',
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#5f6368',
                },
              },
              '& .MuiInputBase-input': {
                color: '#5f6368',
              },
            }}
          />
        </Grid>
      </Grid>

      <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end', gap: 2 }}>
        <Button 
          onClick={onClose}
          sx={{
            color: '#5f6368',
            '&:hover': {
              backgroundColor: 'rgba(95, 99, 104, 0.08)'
            }
          }}
        >
          Cancel
        </Button>
        <Button 
          type="submit" 
          variant="contained"
          sx={{
            background: 'linear-gradient(45deg, #2E7D32 30%, #4CAF50 90%)',
            color: 'white',
            '&:hover': {
              background: 'linear-gradient(45deg, #1B5E20 30%, #388E3C 90%)',
            }
          }}
        >
          Create AI
        </Button>
      </Box>
    </Box>
  );
};

export default CreateAIScratchForm;

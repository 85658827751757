import React from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './store';
import Login from './components/Login';
import Register from './components/Register';
import Dashboard from './components/Dashboard';
import PrivateRoute from './components/PrivateRoute';
import AIFlowBuilder from './components/AIFlow/AIFlowBuilder';
import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline } from '@mui/material';
import theme from './theme/theme';

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Provider store={store}>
        <Router>
          <Switch>
            <Route path="/login" component={Login} />
            <Route path="/register" component={Register} />
            <Route path="/ai-flow" component={AIFlowBuilder} />
            <PrivateRoute path="/" component={Dashboard} />
            <Redirect from="*" to="/login" />
          </Switch>
        </Router>
      </Provider>
    </ThemeProvider>
  );
}

export default App;

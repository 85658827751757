import React, { useState, useRef, useEffect } from 'react';
import { Box, IconButton, Slider, Typography } from '@mui/material';
import { PlayArrow, Pause, VolumeUp, VolumeOff } from '@mui/icons-material';

const AudioPlayer = ({ src, onError }) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [duration, setDuration] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);
  const [volume, setVolume] = useState(1);
  const [isMuted, setIsMuted] = useState(false);
  const audioRef = useRef(null);

  useEffect(() => {
    audioRef.current = new Audio(src);
    
    const handleError = (e) => {
      console.error('Audio error:', e);
      setIsPlaying(false);
      if (onError) onError(e);
    };

    const setAudioData = () => {
      setDuration(audioRef.current.duration);
    };

    const setAudioTime = () => {
      setCurrentTime(audioRef.current.currentTime);
    };

    const handleEnded = () => {
      setIsPlaying(false);
      setCurrentTime(0);
    };

    // Add event listeners
    audioRef.current.addEventListener('loadeddata', setAudioData);
    audioRef.current.addEventListener('timeupdate', setAudioTime);
    audioRef.current.addEventListener('ended', handleEnded);
    audioRef.current.addEventListener('error', handleError);

    // Cleanup function
    return () => {
      if (audioRef.current) {
        audioRef.current.pause();
        audioRef.current.src = ''; // Clear source
        audioRef.current.load(); // Reset audio element
        audioRef.current.removeEventListener('loadeddata', setAudioData);
        audioRef.current.removeEventListener('timeupdate', setAudioTime);
        audioRef.current.removeEventListener('ended', handleEnded);
        audioRef.current.removeEventListener('error', handleError);
        audioRef.current = null; // Clear reference
      }
    };
  }, [src, onError]);

  const playPause = () => {
    if (!audioRef.current) return;

    if (isPlaying) {
      audioRef.current.pause();
    } else {
      audioRef.current.play().catch(error => {
        console.error('Play error:', error);
        if (onError) onError(error);
      });
    }
    setIsPlaying(!isPlaying);
  };

  const onTimeChange = (_, value) => {
    if (!audioRef.current) return;
    
    const time = value;
    setCurrentTime(time);
    audioRef.current.currentTime = time;
  };

  const onVolumeChange = (_, value) => {
    if (!audioRef.current) return;
    
    const vol = value;
    setVolume(vol);
    audioRef.current.volume = vol;
  };

  const toggleMute = () => {
    if (!audioRef.current) return;
    
    if (isMuted) {
      audioRef.current.volume = volume;
      setIsMuted(false);
    } else {
      audioRef.current.volume = 0;
      setIsMuted(true);
    }
  };

  const formatTime = (time) => {
    if (isNaN(time)) return '0:00';
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes}:${seconds.toString().padStart(2, '0')}`;
  };

  return (
    <Box sx={{ 
      display: 'flex', 
      alignItems: 'center', 
      gap: 1,
      bgcolor: '#faf8f6',
      p: 1,
      borderRadius: 1,
      border: '1px solid #e0e0e0'
    }}>
      <IconButton onClick={playPause} size="small">
        {isPlaying ? <Pause /> : <PlayArrow />}
      </IconButton>
      
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="caption" color="text.secondary">
          {formatTime(currentTime)}
        </Typography>
      </Box>

      <Slider
        size="small"
        value={currentTime}
        max={duration}
        onChange={onTimeChange}
        sx={{ 
          mx: 1,
          '& .MuiSlider-thumb': {
            width: 12,
            height: 12,
          }
        }}
      />
      
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="caption" color="text.secondary">
          {formatTime(duration)}
        </Typography>
      </Box>

      <IconButton onClick={toggleMute} size="small">
        {isMuted ? <VolumeOff /> : <VolumeUp />}
      </IconButton>
      
      <Slider
        size="small"
        value={isMuted ? 0 : volume}
        max={1}
        min={0}
        step={0.1}
        onChange={onVolumeChange}
        sx={{ 
          width: 60,
          '& .MuiSlider-thumb': {
            width: 12,
            height: 12,
          }
        }}
      />
    </Box>
  );
};

export default AudioPlayer;

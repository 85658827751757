import React, { useState, useEffect } from 'react';
import { useHistory, useLocation, Route, Switch } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  Grid,
  Card,
  CardContent,
  CircularProgress,
  Paper,
} from '@mui/material';
import {
  Dashboard as DashboardIcon,
  ContactPhone as ContactIcon,
  Campaign as CampaignIcon,
  Assessment as ReportIcon,
  AccountBalanceWallet as BillingIcon,
  Call as CallIcon,
  SmartToy as AIIcon,
  AccountCircle as ProfileIcon,
  Logout as LogoutIcon,
  LocalFireDepartment as HotLeadIcon,
  Whatshot as WarmLeadIcon,
  AcUnit as ColdLeadIcon,
  Timer as DurationIcon,
  CheckCircle as CompletedIcon,
  Cancel as FailedIcon,
  Speed as AvgDurationIcon,
  Timeline as MaxDurationIcon,
  Settings as SettingsIcon,
} from '@mui/icons-material';
import {
  LineChart,
  Line,
  AreaChart,
  Area,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  PieChart,
  Pie,
  Cell,
} from 'recharts';
import { fetchOverviewData } from '../services/apiService';
import { logout } from '../actions/authActions';
import Profile from './Profile';
import ContactList from './ContactList';
import Campaigns from './Campaign';
// import Report from './Report';
import ReportNew from './ReportNew';
import Bills from './Bills';
import IncomingCalls from './IncomingCalls';
import MyAI from './MyAI';
import markaibleLogo from '../assets/markaibleLogoWhiteEBG.png';
import markaibleIcon from '../assets/markaibleIcon.ico';

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

const Dashboard = () => {
  const [isHovered, setIsHovered] = useState(false);
  const [loading, setLoading] = useState(true);
  const [overviewData, setOverviewData] = useState(null);
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  const handleLogout = () => {
    dispatch(logout());
    history.push('/login');
  };
  const bottomMenuItems = [
      {
        title: 'Profile',
        icon: <ProfileIcon />,
        path: '/profile',
      },
      {
        title: 'Billing',
        icon: <BillingIcon />,
        path: '/billing',
      },
    {
      title: 'Logout',
      icon: <LogoutIcon />,
      onClick: handleLogout
    },
  ];

  const menuItems = [
    {
      title: 'Overview',
      icon: <DashboardIcon />,
      path: '/',
    },
    {
      title: 'My AI',
      icon: <AIIcon />,
      path: '/my-ai',
    },
    {
      title: 'In-bound',
      icon: <CallIcon />,
      path: '/incoming-calls',
    },
    {
      title: 'Contact List',
      icon: <ContactIcon />,
      path: '/contact-list',
    },
    {
      title: 'Campaigns',
      icon: <CampaignIcon />,
      path: '/campaign',
    },
    {
      title: 'Reports',
      icon: <ReportIcon />,
      path: '/report',
    },
  ];

  useEffect(() => {
    loadOverviewData();
  }, []);

  const loadOverviewData = async () => {
    setLoading(true);
    try {
      const response = await fetchOverviewData();
      if (response.data) {
        setOverviewData(response.data.overall);
      }
    } catch (error) {
      console.error('Error loading overview data:', error);
    } finally {
      setLoading(false);
    }
  };

  const formatDuration = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`;
  };

  const handleNavigation = (path) => {
    history.push(path);
  };

  const renderMenuItem = (item) => (
    <ListItem
      button
      key={item.title}
      onClick={item.onClick ? item.onClick : () => handleNavigation(item.path)}
      sx={{
        py: 1,
        px: 2,
        borderRadius: '8px',
        mb: 0.5,
        '&:hover': {
          backgroundColor: 'rgba(46, 125, 50, 0.08)',
        },
        ...(location.pathname === item.path && {
          backgroundColor: 'rgba(46, 125, 50, 0.12)',
        }),
      }}
    >
      <ListItemIcon
        sx={{
          minWidth: 40,
          color: location.pathname === item.path ? '#2E7D32' : '#5f6368',
        }}
      >
        {item.icon}
      </ListItemIcon>
      <ListItemText 
        primary={item.title}
        sx={{
          opacity: isHovered ? 1 : 0,
          transition: 'opacity 0.2s',
          '& .MuiTypography-root': {
            fontSize: '0.875rem',
            lineHeight: '1.2',
            fontWeight: location.pathname === item.path ? 600 : 400,
            color: location.pathname === item.path ? '#2E7D32' : '#5f6368',
          },
        }}
      />
    </ListItem>
  );

  const statsData = [
    {
      title: 'Hot Leads',
      value: overviewData?.hotLead || '0',
      icon: <HotLeadIcon />,
      color: '#f44336',
      subtitle: 'Total hot leads generated'
    },
    {
      title: 'Warm Leads',
      value: overviewData?.warmLead || '0',
      icon: <WarmLeadIcon />,
      color: '#ff9800',
      subtitle: 'Total warm leads generated'
    },
    {
      title: 'Cold Leads',
      value: overviewData?.coldLead || '0',
      icon: <ColdLeadIcon />,
      color: '#2196f3',
      subtitle: 'Total cold leads generated'
    },
    {
      title: 'Total Calls',
      value: overviewData?.totalData || '0',
      icon: <CallIcon />,
      color: '#4caf50',
      subtitle: 'Total number of calls'
    },
    {
      title: 'Total Duration',
      value: overviewData ? formatDuration(overviewData.totalDuration) : 'N/A',
      icon: <DurationIcon />,
      color: '#9c27b0',
      subtitle: 'Total call duration'
    },
    {
      title: 'Completed Calls',
      value: overviewData?.completed || '0',
      icon: <CompletedIcon />,
      color: '#4caf50',
      subtitle: 'Successfully completed calls'
    },
    {
      title: 'Failed Calls',
      value: overviewData?.failed || '0',
      icon: <FailedIcon />,
      color: '#f44336',
      subtitle: 'Failed or missed calls'
    },
    {
      title: 'Avg Duration',
      value: overviewData ? formatDuration(Math.round(overviewData.avgDuration)) : 'N/A',
      icon: <AvgDurationIcon />,
      color: '#3f51b5',
      subtitle: 'Average call duration'
    },
    {
      title: 'Max Duration',
      value: overviewData ? formatDuration(overviewData.maxCallDuration) : 'N/A',
      icon: <MaxDurationIcon />,
      color: '#009688',
      subtitle: 'Longest call duration'
    },
  ];

  const leadData = [
    { name: 'Hot Leads', value: overviewData?.hotLead || 0 },
    { name: 'Warm Leads', value: overviewData?.warmLead || 0 },
    { name: 'Cold Leads', value: overviewData?.coldLead || 0 },
  ];

  const callTrendData = overviewData ? [
    { name: 'Mon', calls: overviewData.callsByDay?.Mon || 0 },
    { name: 'Tue', calls: overviewData.callsByDay?.Tue || 0 },
    { name: 'Wed', calls: overviewData.callsByDay?.Wed || 0 },
    { name: 'Thu', calls: overviewData.callsByDay?.Thu || 0 },
    { name: 'Fri', calls: overviewData.callsByDay?.Fri || 0 },
    { name: 'Sat', calls: overviewData.callsByDay?.Sat || 0 },
    { name: 'Sun', calls: overviewData.callsByDay?.Sun || 0 },
  ] : Array(7).fill(0).map((_, i) => ({ 
    name: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'][i], 
    calls: 0 
  }));

  const durationTrendData = overviewData ? [
    { name: 'Mon', duration: overviewData.durationByDay?.Mon || 0 },
    { name: 'Tue', duration: overviewData.durationByDay?.Tue || 0 },
    { name: 'Wed', duration: overviewData.durationByDay?.Wed || 0 },
    { name: 'Thu', duration: overviewData.durationByDay?.Thu || 0 },
    { name: 'Fri', duration: overviewData.durationByDay?.Fri || 0 },
    { name: 'Sat', duration: overviewData.durationByDay?.Sat || 0 },
    { name: 'Sun', duration: overviewData.durationByDay?.Sun || 0 },
  ] : Array(7).fill(0).map((_, i) => ({ 
    name: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'][i], 
    duration: 0 
  }));

  return (
    <Box sx={{ display: 'flex', bgcolor: '#f5f5f5', minHeight: '100vh' }}>
      {/* Sidebar */}
      <Box
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        sx={{
          width: isHovered ? 240 : 72,
          bgcolor: '#ffffff',
          position: 'fixed',
          height: '100vh',
          transition: 'width 0.3s ease',
          borderRight: '1px solid rgba(0, 0, 0, 0.08)',
          display: 'flex',
          flexDirection: 'column',
          zIndex: 1200,
          overflowX: 'hidden',
        }}
      >
        {/* Logo */}
        <Box sx={{ 
          p: 2, 
          display: 'flex', 
          alignItems: 'center',
          justifyContent: isHovered ? 'flex-start' : 'center',
          borderBottom: '1px solid rgba(0, 0, 0, 0.08)',
          minHeight: '64px',
        }}>
          <Box>
            <img 
              src={isHovered ? markaibleLogo : markaibleIcon} 
              alt="MarkAible Logo"
              style={{ 
                height: '32px',
                width: '150px',
                objectFit: 'contain'
              }}
            />
          </Box>
        </Box>

        {/* Menu Items */}
        <List sx={{ 
          flex: 1,
          py: 1,
          px: 1,
          overflowY: 'auto',
          overflowX: 'hidden',
          '&::-webkit-scrollbar': {
            width: '4px',
          },
          '&::-webkit-scrollbar-track': {
            background: 'transparent',
          },
          '&::-webkit-scrollbar-thumb': {
            background: '#bdbdbd',
            borderRadius: '4px',
          },
        }}>
          {menuItems.map((item) => renderMenuItem(item))}
        </List>

        <List sx={{ py: 1, px: 1 }}>
          {bottomMenuItems.map((item) => renderMenuItem(item))}
        </List>
      </Box>

      {/* Main Content */}
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          ml: isHovered ? '240px' : '72px',
          transition: 'margin-left 0.3s ease',
          bgcolor: '#f5f5f5',
        }}
      >
        <Switch>
          <Route exact path="/">
            <Box>
              <Typography variant="h5" sx={{ mb: 3, color: '#5f6368', fontWeight: 500, fontFamily: 'Raleway' }}>
                Overview
              </Typography>

              {loading ? (
                <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
                  <CircularProgress sx={{ color: '#2E7D32' }} />
                </Box>
              ) : (
                <>
                  <Grid container spacing={3}>
                    {statsData.map((stat, index) => (
                      <Grid item xs={12} sm={6} md={3} key={index}>
                        <Card
                          sx={{
                            height: '100%',
                            backgroundColor: '#ffffff',
                            boxShadow: '0 2px 4px rgba(0, 0, 0, 0.05)',
                            transition: 'transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out',
                            '&:hover': {
                              transform: 'translateY(-2px)',
                              boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                            },
                          }}
                        >
                          <CardContent>
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                              <Box
                                sx={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                  width: 40,
                                  height: 40,
                                  borderRadius: '50%',
                                  backgroundColor: `${stat.color}20`,
                                  color: stat.color,
                                  mr: 2,
                                }}
                              >
                                {stat.icon}
                              </Box>
                              <Box>
                                <Typography variant="h6" sx={{ color: stat.color, fontWeight: 600, fontFamily: 'Raleway' }}>
                                  {stat.value}
                                </Typography>
                                <Typography variant="caption" sx={{ color: '#5f6368', fontFamily: 'Raleway' }}>
                                  {stat.title}
                                </Typography>
                              </Box>
                            </Box>
                          </CardContent>
                        </Card>
                      </Grid>
                    ))}
                  </Grid>

                  <Grid container spacing={3} sx={{ mt: 2 }}>
                    {/* Lead Distribution */}
                    <Grid item xs={12} md={4}>
                      <Paper sx={{ p: 3, height: '400px', backgroundColor: 'transparent' }}>
                        <Typography variant="h6" sx={{ mb: 2, color: '#5f6368', fontFamily: 'Raleway' }}>
                          Lead Distribution
                        </Typography>
                        <ResponsiveContainer width="100%" height={300}>
                          <PieChart>
                            <Pie
                              data={leadData}
                              cx="50%"
                              cy="50%"
                              innerRadius={60}
                              outerRadius={80}
                              fill="#8884d8"
                              paddingAngle={5}
                              dataKey="value"
                              label={({ name, value }) => `${name}: ${value}`}
                              labelLine={false}
                            >
                              {leadData.map((entry, index) => (
                                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                              ))}
                            </Pie>
                            <Tooltip contentStyle={{ backgroundColor: '#fff', border: 'none' }} />
                          </PieChart>
                        </ResponsiveContainer>
                      </Paper>
                    </Grid>

                    {/* Call Trends */}
                    <Grid item xs={12} md={4}>
                      <Paper sx={{ p: 3, height: '400px', backgroundColor: 'transparent' }}>
                        <Typography variant="h6" sx={{ mb: 2, color: '#5f6368', fontFamily: 'Raleway' }}>
                          Call Trends
                        </Typography>
                        <ResponsiveContainer width="100%" height={300}>
                          <LineChart data={callTrendData}>
                            <XAxis dataKey="name" />
                            <YAxis hide={true} />
                            <Tooltip />
                            <Line
                              type="monotone"
                              dataKey="calls"
                              stroke="#2E7D32"
                              strokeWidth={2}
                              dot={{ r: 4 }}
                              activeDot={{ r: 6 }}
                            />
                          </LineChart>
                        </ResponsiveContainer>
                      </Paper>
                    </Grid>

                    {/* Duration Trends */}
                    <Grid item xs={12} md={4}>
                      <Paper sx={{ p: 3, height: '400px', backgroundColor: 'transparent' }}>
                        <Typography variant="h6" sx={{ mb: 2, color: '#5f6368', fontFamily: 'Raleway' }}>
                          Duration Trends
                        </Typography>
                        <ResponsiveContainer width="100%" height={300}>
                          <LineChart data={durationTrendData}>
                            <XAxis dataKey="name" />
                            <YAxis hide={true} />
                            <Tooltip />
                            <Line
                              type="monotone"
                              dataKey="duration"
                              stroke="#2E7D32"
                              strokeWidth={2}
                              dot={{ r: 4 }}
                              activeDot={{ r: 6 }}
                            />
                          </LineChart>
                        </ResponsiveContainer>
                      </Paper>
                    </Grid>
                  </Grid>
                </>
              )}
            </Box>
          </Route>
          <Route path="/profile" component={Profile} />
          <Route path="/contact-list" component={ContactList} />
          <Route path="/campaign" component={Campaigns} />
          {/* <Route path="/report" component={Report} /> */}
          <Route path="/report" component={ReportNew} />
          <Route path="/billing" component={Bills} />
          <Route path="/incoming-calls" component={IncomingCalls} />
          <Route path="/my-ai" component={MyAI} />
        </Switch>
      </Box>
    </Box>
  );
};

export default Dashboard;

import { FETCH_CALLS_REQUEST, FETCH_CALLS_SUCCESS, FETCH_CALLS_FAILURE } from "./types";

export const fetchIncomingCalls = (phoneNumber) => async (dispatch) => {
    dispatch({ type: FETCH_CALLS_REQUEST });
    try {
      const response = await fetch('https://application.glimpass.com/exotel/get-incoming-call-details', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ phoneNumber })
      });
      const data = await response.json();
      dispatch({ type: FETCH_CALLS_SUCCESS, payload: data });
    } catch (error) {
      dispatch({ type: FETCH_CALLS_FAILURE, error: error.message });
    }
  };
  
export const fetchIncomingCallsByNumber = (fromNumber, sortOrder = 'desc', dateRange = null) => async (dispatch) => {
  dispatch({ type: FETCH_CALLS_REQUEST });
  try {
    // Prepare request payload
    const payload = {
      fromNumber,
      sortOrder // 'desc' for newest first, 'asc' for oldest first
    };
    
    // Add date range if provided
    if (dateRange && dateRange.from && dateRange.to) {
      payload.fromDate = dateRange.from;
      payload.toDate = dateRange.to;
    }
    
    console.log("Sending API request with payload:", payload);
    
    const response = await fetch('https://application.glimpass.com/plivo/get-incoming-by-number', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(payload)
    });
    
    const data = await response.json();
    console.log("API Response in action creator:", data);
    
    // Directly use the data array if it's an array, otherwise look for data.calls
    const callsData = Array.isArray(data) ? data : (data.calls || data.data || []);
    console.log("Dispatching to store:", callsData.length, "calls");
    
    dispatch({ type: FETCH_CALLS_SUCCESS, payload: callsData });
    return { data: callsData }; // Return in a consistent format
  } catch (error) {
    console.error("Error in fetchIncomingCallsByNumber:", error);
    dispatch({ type: FETCH_CALLS_FAILURE, error: error.message });
    throw error;
  }
};
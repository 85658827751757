import React, { useState, useEffect } from 'react';
import {
  Box,
  Grid,
  Card,
  CardContent,
  Typography,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
} from '@mui/material';
import {
  AccountBalance as BalanceIcon,
  Block as BlockedIcon,
  Campaign as CampaignIcon,
} from '@mui/icons-material';
import { fetchBillingHistory, fetchClientInfo } from '../services/apiService';

const Bills = () => {
  const [billingData, setBillingData] = useState([]);
  const [clientInfo, setClientInfo] = useState(null);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  useEffect(() => {
    const loadData = async () => {
      setLoading(true);
      try {
        const clientId = JSON.parse(localStorage.getItem('auth'))?._id;
        console.log(clientId);
        const [billingResponse, clientResponse] = await Promise.all([
          fetchBillingHistory(clientId),
          fetchClientInfo(clientId)
        ]);
        console.log(billingResponse, clientResponse);
        setBillingData(billingResponse);
        setClientInfo(clientResponse);
      } catch (error) {
        console.error('Error loading data:', error);
      } finally {
        setLoading(false);
      }
    };

    loadData();
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleDateString('en-IN', {
      day: '2-digit',
      month: 'short',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
    });
  };

  const formatCurrency = (amount) => {
    return new Intl.NumberFormat('en-IN', {
      // style: 'currency',
      // currency: 'EUR',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(amount);
  };

  const summaryCards = clientInfo ? [
    {
      title: 'Available Credits',
      value: formatCurrency(clientInfo.availableBalance),
      icon: <BalanceIcon sx={{ fontSize: 40, color: '#2E7D32' }} />,
      color: '#2E7D32',
    },
    {
      title: 'Blocked Credits',
      value: formatCurrency(clientInfo.blockedBalance),
      icon: <BlockedIcon sx={{ fontSize: 40, color: '#d32f2f' }} />,
      color: '#d32f2f',
    },
    {
      title: 'Active Campaigns',
      value: clientInfo.activeCampId || 'N/A',
      icon: <CampaignIcon sx={{ fontSize: 40, color: '#1976d2' }} />,
      color: '#1976d2',
    },
  ] : [];

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h5" sx={{ mb: 3, color: '#5f6368', fontWeight: 500 }}>
        Billing Overview
      </Typography>

      {/* Summary Cards */}
      <Grid container spacing={3} sx={{ mb: 4 }}>
        {summaryCards.map((card, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Card
              sx={{
                height: '100%',
                backgroundColor: '#ffffff',
                boxShadow: '0 2px 4px rgba(0,0,0,0.05)',
                transition: 'transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out',
                '&:hover': {
                  transform: 'translateY(-2px)',
                  boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
                },
              }}
            >
              <CardContent>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      width: 48,
                      height: 48,
                      borderRadius: '50%',
                      backgroundColor: `${card.color}20`,
                      mr: 2,
                    }}
                  >
                    {card.icon}
                  </Box>
                  <Box>
                    <Typography variant="h6" sx={{ color: card.color, fontWeight: 600 }}>
                      {card.value}
                    </Typography>
                    <Typography variant="body2" sx={{ color: '#5f6368' }}>
                      {card.title}
                    </Typography>
                  </Box>
                </Box>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>

      {/* Transaction History */}
      <Typography variant="h6" sx={{ mb: 2, color: '#5f6368' }}>
        Transaction History
      </Typography>
      <TableContainer 
        component={Paper} 
        sx={{ 
          boxShadow: '0 2px 4px rgba(0,0,0,0.05)',
          backgroundColor: 'transparent',
        }}
      >
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={{ color: '#5f6368', fontWeight: 500 }}>Date</TableCell>
              <TableCell sx={{ color: '#5f6368', fontWeight: 500 }}>Description</TableCell>
              <TableCell sx={{ color: '#5f6368', fontWeight: 500 }}>Campaign</TableCell>
              <TableCell sx={{ color: '#5f6368', fontWeight: 500 }} align="right">Amount</TableCell>
              <TableCell sx={{ color: '#5f6368', fontWeight: 500 }} align="right">Type</TableCell>
              <TableCell sx={{ color: '#5f6368', fontWeight: 500 }} align="right">Balance</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {billingData
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((transaction) => (
                <TableRow 
                  key={transaction._id}
                  sx={{ 
                    '&:hover': { backgroundColor: '#f5f5f5' },
                    '& td': { color: '#5f6368' }
                  }}
                >
                  <TableCell>{formatDate(transaction.date)}</TableCell>
                  <TableCell>{transaction.desc || '-'}</TableCell>
                  <TableCell>{transaction.camp_name || '-'}</TableCell>
                  <TableCell align="right">{transaction.balanceCount}</TableCell>
                  <TableCell align="right">{transaction.type || '-'}</TableCell>
                  <TableCell align="right">{transaction.balance}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={billingData.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        sx={{
          '.MuiTablePagination-selectLabel, .MuiTablePagination-displayedRows': {
            color: '#5f6368',
          },
        }}
      />
    </Box>
  );
};

export default Bills;

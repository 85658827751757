import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Typography,
  Box,
  IconButton,
} from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import { updateAssistantDetails } from '../../services/apiService';

const formLabelStyle = {
  color: '#1a1a1a',
  fontWeight: 500,
  mb: 1
};

const EditAssistantModal = ({ open, onClose, assistant, onSave }) => {
  const [formData, setFormData] = useState({
    agentFirstMessage: '',
    systemPrompt: ''
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (assistant) {
      setFormData({
        agentFirstMessage: assistant.agent_welcome_message || '',
        systemPrompt: assistant.system_prompt || ''
      });
    }
  }, [assistant]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSubmit = async () => {
    if (!assistant) return;
    
    setLoading(true);
    try {
      await updateAssistantDetails(
        assistant._id, 
        formData.agentFirstMessage, 
        formData.systemPrompt
      );
      alert('Assistant details updated successfully');
      onClose();
    } catch (error) {
      console.error('Error updating assistant:', error);
      alert('Error updating assistant details');
    } finally {
      setLoading(false);
    }
  };

  if (!assistant) return null;

  return (
    <Dialog 
      open={open} 
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      PaperProps={{
        sx: {
          borderRadius: 2,
          backgroundColor: '#faf8f6'
        }
      }}
    >
      <DialogTitle sx={{ 
        display: 'flex', 
        justifyContent: 'space-between',
        alignItems: 'center',
        color: '#000000'
      }}>
        Edit AI Assistant
        <IconButton onClick={onClose} size="small">
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Box sx={{ mt: 2 }}>
          <Typography sx={formLabelStyle}>Agent Name</Typography>
          <TextField
            fullWidth
            value={assistant.agent_name || ''}
            disabled
            margin="dense"
            sx={{
              '& .MuiOutlinedInput-root': {
                backgroundColor: '#faf8f6',
                '& fieldset': {
                  borderColor: 'rgba(95, 99, 104, 0.2)',
                },
                '& input': {
                  color: '#5f6368',
                },
                '&.Mui-disabled': {
                  backgroundColor: '#f0ede9',
                  '& fieldset': {
                    borderColor: 'rgba(95, 99, 104, 0.1)',
                  },
                },
              },
            }}
          />
          <Box sx={{ mt: 2 }}>
            <Typography sx={formLabelStyle}>Agent First Message</Typography>
            <TextField
              fullWidth
              label="Agent First Message"
              name="agentFirstMessage"
              value={formData.agentFirstMessage}
              onChange={handleChange}
              sx={{
                mb: 3,
                '& .MuiInputBase-input': { color: '#000000' },
                '& .MuiInputLabel-root': { color: '#666666' },
                '& .MuiOutlinedInput-root': {
                  backgroundColor: '#faf8f6',
                  '& fieldset': {
                    borderColor: '#e0e0e0',
                  },
                  '&:hover fieldset': {
                    borderColor: '#666666',
                  },
                }
              }}
            />
          </Box>
          <Box sx={{ mt: 2 }}>
            <Typography sx={formLabelStyle}>System Prompt</Typography>
            <TextField
              fullWidth
              label="System Prompt"
              name="systemPrompt"
              value={formData.systemPrompt}
              onChange={handleChange}
              multiline
              rows={4}
              sx={{
                '& .MuiInputBase-input': { color: '#000000' },
                '& .MuiInputLabel-root': { color: '#666666' },
                '& .MuiOutlinedInput-root': {
                  backgroundColor: '#faf8f6',
                  '& fieldset': {
                    borderColor: '#e0e0e0',
                  },
                  '&:hover fieldset': {
                    borderColor: '#666666',
                  },
                }
              }}
            />
          </Box>
        </Box>
      </DialogContent>
      <DialogActions sx={{ p: 2 }}>
        <Button onClick={onClose} sx={{ color: '#666666' }}>Cancel</Button>
        <Button 
          onClick={handleSubmit}
          variant="contained"
          disabled={loading}
          sx={{
            backgroundColor: '#007AFF',
            '&:hover': {
              backgroundColor: '#0056b3'
            },
            '&:disabled': {
              backgroundColor: '#0056b3',
              color: 'rgba(95, 99, 104, 0.5)',
            }
          }}
        >
          {loading ? 'Saving...' : 'Save Changes'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditAssistantModal;

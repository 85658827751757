import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, Link } from 'react-router-dom';
import { login } from '../actions/authActions';
import { 
  TextField, 
  Button, 
  Typography, 
  Paper, 
  Box, 
  useTheme, 
  useMediaQuery,
  IconButton,
  InputAdornment
} from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import logo from '../assets/markaibleLogoWhiteEBG.png';

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(4),
    },
  },
  paper: {
    display: 'flex',
    width: '100%',
    maxWidth: '1000px',
    minHeight: '600px',
    borderRadius: theme.spacing(2),
    overflow: 'hidden',
    flexDirection: 'column',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
    },
  },
  logoSection: {
    flex: '0 0 auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(4),
    backgroundColor: '#f5f5f5',
    [theme.breakpoints.up('sm')]: {
      flex: '0 0 40%',
    },
  },
  formSection: {
    flex: '1 1 auto',
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(4),
    [theme.breakpoints.up('sm')]: {
      flex: '0 0 60%',
      padding: theme.spacing(8, 6),
    },
  },
  logo: {
    width: '70%',
    maxWidth: '200px',
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      width: '90%',
      maxWidth: '300px',
      marginBottom: theme.spacing(4),
    },
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    padding: theme.spacing(1.5),
  },
  registerLink: {
    marginTop: theme.spacing(2),
    textAlign: 'center',
  },
  welcomeText: {
    color: '#666',
    marginTop: theme.spacing(2),
    textAlign: 'center',
    [theme.breakpoints.down('xs')]: {
      fontSize: '1.2rem',
    },
  },
  descriptionText: {
    color: '#888',
    marginTop: theme.spacing(1),
    textAlign: 'center',
    [theme.breakpoints.down('xs')]: {
      fontSize: '0.875rem',
    },
  },
  title: {
    color: '#333',
    marginBottom: theme.spacing(3),
    [theme.breakpoints.down('xs')]: {
      fontSize: '1.5rem',
      marginBottom: theme.spacing(2),
    },
  },
}));

const Login = () => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const [credentials, setCredentials] = useState({ email: '', password: '' });
  const [showPassword, setShowPassword] = useState(false);
  const dispatch = useDispatch();
  const isAuthenticated = useSelector(state => state.auth.isAuthenticated);
  const error = useSelector(state => state.auth.error);
  const history = useHistory();

  useEffect(() => {
    if (isAuthenticated) {
      history.push('/');
    }
  }, [isAuthenticated, history]);

  const handleChange = (e) => {
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(login(credentials));
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <div className={classes.root}>
      <Paper className={classes.paper} elevation={6}>
        <Box className={classes.logoSection}>
          <img src={logo} alt="Markaible Logo" className={classes.logo} />
          <Typography variant={isMobile ? "h6" : "h5"} className={classes.welcomeText}>
            Welcome Back!
          </Typography>
          <Typography variant="body2" className={classes.descriptionText}>
            Sign in to continue to your dashboard
          </Typography>
        </Box>
        <Box className={classes.formSection}>
          <Typography component="h1" variant={isMobile ? "h5" : "h4"} className={classes.title}>
            Sign In
          </Typography>
          <form className={classes.form} noValidate onSubmit={handleSubmit}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
              onChange={handleChange}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type={showPassword ? 'text' : 'password'}
              id="password"
              autoComplete="current-password"
              onChange={handleChange}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              Sign In
            </Button>
            {error && <Typography color="error" style={{ marginTop: 16 }}>{error}</Typography>}
            <Box className={classes.registerLink}>
              <Typography variant="body2">
                Don't have an account? <Link to="/register" style={{ color: theme.palette.primary.main }}>Register now</Link>
              </Typography>
            </Box>
          </form>
        </Box>
      </Paper>
    </div>
  );
};

export default Login;

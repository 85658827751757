import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  typography: {
    fontFamily: [
      'Raleway',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    allVariants: {
      color: '#666666',
    },
    button: {
      textTransform: 'none',
      color: '#FFFFFF',
    },
    h1: {
      fontFamily: 'Raleway',
      fontWeight: 600
    },
    h2: {
      fontFamily: 'Raleway',
      fontWeight: 600
    },
    h3: {
      fontFamily: 'Raleway',
      fontWeight: 600
    },
    h4: {
      fontFamily: 'Raleway',
      fontWeight: 600
    },
    h5: {
      fontFamily: 'Raleway',
      fontWeight: 600
    },
    h6: {
      fontFamily: 'Raleway',
      fontWeight: 600
    },
  },
  palette: {
    background: {
      default: '#faf8f6',
      paper: '#ffffff'
    },
    primary: {
      main: '#2E7D32',
      light: '#4CAF50',
      dark: '#1B5E20',
      contrastText: '#ffffff',
    },
    text: {
      primary: '#666666',
      secondary: '#666666',
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          backgroundColor: '#faf8f6',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        contained: {
          background: 'linear-gradient(45deg, #2E7D32 30%, #4CAF50 90%)',
          color: '#FFFFFF',
          fontWeight: 500,
          '&:hover': {
            background: 'linear-gradient(45deg, #1B5E20 30%, #388E3C 90%)',
          },
        },
        outlined: {
          color: '#666666',
          '&:hover': {
            backgroundColor: 'rgba(0,0,0,0.04)',
          },
        },
        text: {
          color: '#666666',
          '&:hover': {
            backgroundColor: 'rgba(0,0,0,0.04)',
          },
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        primary: {
          fontFamily: 'Raleway',
          fontWeight: 500
        }
      }
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: '#ffffff',
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiInputBase-input': {
            color: '#666666',
            fontFamily: [
              '-apple-system',
              'BlinkMacSystemFont',
              '"Segoe UI"',
              'Roboto',
              '"Helvetica Neue"',
              'Arial',
              'sans-serif',
            ].join(','),
          },
          '& .MuiInputLabel-root': {
            color: '#666666',
          },
          '& .MuiOutlinedInput-root': {
            backgroundColor: '#faf8f6',
            '& fieldset': {
              borderColor: '#e0e0e0',
            },
            '&:hover fieldset': {
              borderColor: '#666666',
            },
          },
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
          ].join(','),
          '&.profile-heading': {
            fontFamily: 'Raleway',
            fontWeight: 600
          }
        },
      },
    },
  },
});

export default theme;

import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Box,
  Card,
  CardContent,
  CardActionArea,
  Grid,
  IconButton,
  LinearProgress,
} from '@mui/material';
import { 
  Close as CloseIcon, 
  Code as CodeIcon,
  AccountTree as AccountTreeIcon 
} from '@mui/icons-material';
import { useHistory } from 'react-router-dom';
import CreateAIScratchForm from './CreateAIScratchForm';

const CreateAIModal = ({ open, onClose }) => {
  const [selectedOption, setSelectedOption] = useState(null);
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const handleOptionSelect = (option) => {
    setSelectedOption(option);
    if (option === 'flow') {
      onClose();
      history.push('/ai-flow');
    }
  };

  const handleClose = () => {
    setSelectedOption(null);
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="md"
      fullWidth
      PaperProps={{
        sx: {
          borderRadius: 2,
          backgroundColor: '#faf8f6'
        }
      }}
    >
      <DialogTitle sx={{ 
        display: 'flex', 
        justifyContent: 'space-between',
        alignItems: 'center',
        color: '#5f6368',
        fontFamily: 'Raleway',
        pb: 1
      }}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <Typography variant="h6" sx={{ fontFamily: 'Raleway', fontWeight: 600 }}>
            Create New AI Assistant
          </Typography>
        </Box>
        <IconButton onClick={handleClose} size="small">
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        {loading && <LinearProgress />}
        {!selectedOption && (
          <>
            <Typography 
              variant="body1" 
              color="text.secondary" 
              sx={{ 
                mb: 3,
                fontFamily: 'Raleway'
              }}
            >
              Choose how you want to create your AI assistant
            </Typography>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <Card 
                  sx={{ 
                    height: '100%',
                    backgroundColor: '#faf8f6',
                    transition: 'all 0.3s ease',
                    '&:hover': {
                      transform: 'translateY(-4px)',
                      boxShadow: '0 4px 20px rgba(0,0,0,0.1)'
                    }
                  }}
                >
                  <CardActionArea 
                    onClick={() => handleOptionSelect('scratch')}
                    sx={{ height: '100%', p: 2 }}
                  >
                    <CardContent>
                      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mb: 2 }}>
                        <CodeIcon sx={{ fontSize: 40, color: '#5f6368', mb: 1 }} />
                        <Typography 
                          variant="h6" 
                          gutterBottom 
                          sx={{ 
                            fontWeight: 600, 
                            color: '#000000',
                            fontFamily: 'Raleway',
                            textAlign: 'center'
                          }}
                        >
                          Create AI from Scratch
                        </Typography>
                      </Box>
                      <Typography 
                        variant="body2" 
                        color="text.secondary"
                        sx={{ fontFamily: 'Raleway', textAlign: 'center' }}
                      >
                        Build a custom AI assistant by defining its personality, voice, and behavior through prompts and configurations.
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Grid>
              <Grid item xs={12} md={6}>
                <Card 
                  sx={{ 
                    height: '100%',
                    backgroundColor: '#faf8f6',
                    transition: 'all 0.3s ease',
                    '&:hover': {
                      transform: 'translateY(-4px)',
                      boxShadow: '0 4px 20px rgba(0,0,0,0.1)'
                    }
                  }}
                >
                  <CardActionArea 
                    onClick={() => handleOptionSelect('flow')}
                    sx={{ height: '100%', p: 2 }}
                  >
                    <CardContent>
                      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mb: 2 }}>
                        <AccountTreeIcon sx={{ fontSize: 40, color: '#5f6368', mb: 1 }} />
                        <Typography 
                          variant="h6" 
                          gutterBottom 
                          sx={{ 
                            fontWeight: 600, 
                            color: '#000000',
                            fontFamily: 'Raleway',
                            textAlign: 'center'
                          }}
                        >
                          Create AI Flow
                        </Typography>
                      </Box>
                      <Typography 
                        variant="body2" 
                        color="text.secondary"
                        sx={{ fontFamily: 'Raleway', textAlign: 'center' }}
                      >
                        Design your AI assistant using our visual flow builder with pre-built templates and customizable nodes.
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Grid>
            </Grid>
          </>
        )}
        {selectedOption === 'scratch' && (
          <CreateAIScratchForm onBack={() => setSelectedOption(null)} onClose={onClose} />
        )}
      </DialogContent>

      {!selectedOption && (
        <DialogActions sx={{ p: 2 }}>
          <Button onClick={handleClose}>Cancel</Button>
        </DialogActions>
      )}
    </Dialog>
  );
};

export default CreateAIModal;

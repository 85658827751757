import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box,
  TextField,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  IconButton,
  Button,
  TablePagination,
  LinearProgress,
  Typography,
  InputAdornment,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
import {
  Refresh as RefreshIcon,
  Add as AddIcon,
  Search as SearchIcon,
  CalendarMonth as CalendarIcon,
  Close as CloseIcon,
} from '@mui/icons-material';
import { format } from 'date-fns';
import { getCampaigns } from '../actions/campaignActions';
import { getContactLists } from '../actions/contactActions';
import StartNewCampaignModal from './modals/StartNewCampaign';
import ProviderSelector from './helper/ProviderSelector';

const Campaign = () => {
  const dispatch = useDispatch();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchTerm, setSearchTerm] = useState('');
  const [startModalOpen, setStartModalOpen] = useState(false);
  const [filterDate, setFilterDate] = useState(null);
  const [dateDialogOpen, setDateDialogOpen] = useState(false);
  const { campaigns, loading } = useSelector((state) => state.campaign);
  const { lists } = useSelector((state) => state.contact);

  useEffect(() => {
    dispatch(getCampaigns());
    dispatch(getContactLists());
  }, [dispatch]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleRefresh = () => {
    dispatch(getCampaigns());
    dispatch(getContactLists());
  };

  const handleDateSelect = (date) => {
    setFilterDate(date);
    setDateDialogOpen(false);
  };

  const getListName = (listId) => {
    const list = lists?.find(l => l._id === listId);
    return list ? list.name : 'N/A';
  };

  const getCampaignData = (campaign) => {
    try {
      const currentProvider = localStorage.getItem('provider') || 'plivo';
      
      if (currentProvider === 'plivo') {
        return {
          id: campaign._id,
          name: campaign.campaignName,
          listId: campaign.listId,
          listName: getListName(campaign.listId),
          createdAt: campaign.createdAt
        };
      } else {
        // For Exotel
        const data = JSON.parse(campaign.responseFromCamp)?.response?.[0]?.data;
        return {
          id: campaign._id,
          name: data?.name || 'N/A',
          listId: data?.lists?.[0] || 'No lists',
          listName: data?.lists ? getListName(data.lists[0]) : 'N/A',
          createdAt: data?.date_created || 'N/A'
        };
      }
    } catch (error) {
      console.error('Error processing campaign data:', error);
      return {
        id: campaign._id || 'N/A',
        name: 'Error processing data',
        listId: 'N/A',
        listName: 'N/A',
        createdAt: 'N/A'
      };
    }
  };

  const filteredCampaigns = Array.isArray(campaigns) ? campaigns.filter(campaign => {
    try {
      const campaignData = getCampaignData(campaign);
      const searchMatch = searchTerm === '' || 
        campaignData.id.toLowerCase().includes(searchTerm.toLowerCase()) ||
        campaignData.name.toLowerCase().includes(searchTerm.toLowerCase());
      
      if (!searchMatch) return false;

      if (filterDate) {
        const campaignDate = new Date(campaignData.createdAt);
        const filterDateStart = new Date(filterDate);
        filterDateStart.setHours(0, 0, 0, 0);
        const filterDateEnd = new Date(filterDate);
        filterDateEnd.setHours(23, 59, 59, 999);
        
        return campaignDate >= filterDateStart && campaignDate <= filterDateEnd;
      }

      return true;
    } catch (error) {
      console.error('Error filtering campaign:', error);
      return false;
    }
  }) : [];

  return (
    <Box sx={{ p: 3, bgcolor: '#F9FAFB' }}>
      {/* Header */}
      <Box sx={{ 
        display: 'flex', 
        justifyContent: 'space-between', 
        alignItems: 'center',
        mb: 3,
      }}>
        <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
          <TextField
            size="small"
            placeholder="Search by ID or name..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon sx={{ color: 'grey' }} />
                </InputAdornment>
              ),
            }}
            sx={{
              width: 300,
              '& .MuiOutlinedInput-root': {
                backgroundColor: '#ffffff',
                '& input': {
                  color: 'grey',
                },
                '& fieldset': {
                  borderColor: '#E5E7EB',
                },
                '&:hover fieldset': {
                  borderColor: '#D1D5DB',
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#2E7D32',
                },
              },
              '& .MuiInputLabel-root': {
                color: 'grey',
              },
              '& .MuiInputBase-input::placeholder': {
                color: 'grey',
                opacity: 1,
              },
            }}
          />
          <IconButton onClick={handleRefresh}>
            <RefreshIcon sx={{ color: 'grey' }} />
          </IconButton>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <IconButton 
              onClick={() => setDateDialogOpen(true)}
              sx={{ color: filterDate ? '#2E7D32' : 'grey' }}
            >
              <CalendarIcon />
            </IconButton>
            {filterDate && (
              <Button 
                size="small" 
                onClick={() => setFilterDate(null)}
                sx={{ 
                  minWidth: 'auto',
                  p: 0.5,
                  color: 'grey',
                  '&:hover': {
                    backgroundColor: 'rgba(0, 0, 0, 0.04)'
                  }
                }}
              >
                Clear
              </Button>
            )}
          </Box>
        </Box>
        <Box sx={{ display: 'flex', gap: 2 }}>
          <ProviderSelector />
          <Button
            variant="contained"
            color="primary"
            startIcon={<AddIcon />}
            onClick={() => setStartModalOpen(true)}
            sx={{
              background: 'linear-gradient(45deg, #2E7D32 30%, #4CAF50 90%)',
              color: 'white',
              boxShadow: '0 3px 5px 2px rgba(76, 175, 80, .3)',
              '&:hover': {
                background: 'linear-gradient(45deg, #1B5E20 30%, #388E3C 90%)',
              },
            }}
          >
            Start Campaign
          </Button>
        </Box>
      </Box>

      {/* Table */}
      <TableContainer component={Paper} sx={{ 
        bgcolor: '#FFFFFF',
        boxShadow: '0 1px 3px rgba(0, 0, 0, 0.05)',
        borderRadius: '8px',
        overflow: 'hidden'
      }}>
        <Table>
          <TableHead>
            <TableRow sx={{ bgcolor: '#F3F4F6' }}>
              <TableCell sx={{ color: 'grey', fontWeight: 600 }}>Campaign ID</TableCell>
              <TableCell sx={{ color: 'grey', fontWeight: 600 }}>Campaign Name</TableCell>
              <TableCell sx={{ color: 'grey', fontWeight: 600 }}>List Name</TableCell>
              <TableCell sx={{ color: 'grey', fontWeight: 600 }}>Created At</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableRow>
                <TableCell colSpan={4} align="center">
                  <LinearProgress sx={{ my: 2 }} />
                </TableCell>
              </TableRow>
            ) : filteredCampaigns.length === 0 ? (
              <TableRow>
                <TableCell colSpan={4} align="center">
                  <Typography variant="body2" sx={{ color: 'grey', py: 2 }}>
                    No campaigns found
                  </Typography>
                </TableCell>
              </TableRow>
            ) : (
              filteredCampaigns
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((campaign) => {
                  const data = getCampaignData(campaign);
                  return (
                    <TableRow key={data.id} hover>
                      <TableCell sx={{ color: 'grey' }}>{data.id}</TableCell>
                      <TableCell sx={{ color: 'grey' }}>{data.name}</TableCell>
                      <TableCell sx={{ color: 'grey' }}>{data.listName}</TableCell>
                      <TableCell sx={{ color: 'grey' }}>
                        {typeof data.createdAt === 'string' 
                          ? format(new Date(data.createdAt), 'MMM dd, yyyy HH:mm')
                          : 'N/A'
                        }
                      </TableCell>
                    </TableRow>
                  );
                })
            )}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={filteredCampaigns.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>

      {/* Date Selection Dialog */}
      <Dialog 
        open={dateDialogOpen} 
        onClose={() => setDateDialogOpen(false)}
        PaperProps={{
          sx: { 
            borderRadius: 2,
            maxWidth: '400px'
          }
        }}
      >
        <DialogTitle sx={{ 
          display: 'flex', 
          justifyContent: 'space-between', 
          alignItems: 'center',
          pb: 1
        }}>
          <Typography variant="h6" sx={{ fontFamily: 'Raleway' }}>
            Select Date
          </Typography>
          <IconButton 
            onClick={() => setDateDialogOpen(false)}
            size="small"
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Box sx={{ p: 2 }}>
            <input
              type="date"
              value={filterDate ? format(new Date(filterDate), 'yyyy-MM-dd') : ''}
              onChange={(e) => handleDateSelect(e.target.value)}
              style={{
                padding: '8px',
                border: '1px solid #E5E7EB',
                borderRadius: '4px',
                width: '100%',
                color: '#5f6368',
                fontFamily: 'inherit'
              }}
            />
          </Box>
        </DialogContent>
        <DialogActions sx={{ p: 2, pt: 0 }}>
          <Button 
            onClick={() => setDateDialogOpen(false)}
            sx={{ color: 'grey' }}
          >
            Cancel
          </Button>
          <Button 
            onClick={() => handleDateSelect(filterDate)}
            variant="contained"
            sx={{
              bgcolor: '#2E7D32',
              '&:hover': {
                bgcolor: '#1B5E20'
              }
            }}
          >
            Apply
          </Button>
        </DialogActions>
      </Dialog>

      <StartNewCampaignModal
        open={startModalOpen}
        onClose={() => setStartModalOpen(false)}
      />
    </Box>
  );
};

export default Campaign;

import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { register } from '../actions/authActions';
import { 
  TextField, 
  Button, 
  Typography, 
  Paper, 
  Box, 
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  InputAdornment,
  useTheme,
  useMediaQuery,
  IconButton
} from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import logo from '../assets/markaibleLogoWhiteEBG.png';

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(4),
    },
  },
  paper: {
    display: 'flex',
    width: '100%',
    maxWidth: '1200px',
    minHeight: '600px',
    borderRadius: theme.spacing(2),
    overflow: 'hidden',
    flexDirection: 'column',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
    },
  },
  logoSection: {
    flex: '0 0 auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(4),
    backgroundColor: '#f5f5f5',
    [theme.breakpoints.up('sm')]: {
      flex: '0 0 35%',
    },
  },
  formSection: {
    flex: '1 1 auto',
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(4),
    overflowY: 'auto',
    [theme.breakpoints.up('sm')]: {
      flex: '0 0 65%',
      padding: theme.spacing(4, 6),
    },
  },
  logo: {
    width: '70%',
    maxWidth: '200px',
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      width: '90%',
      maxWidth: '280px',
      marginBottom: theme.spacing(4),
    },
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    padding: theme.spacing(1.5),
  },
  loginLink: {
    marginTop: theme.spacing(2),
    textAlign: 'center',
  },
  formControl: {
    width: '100%',
  },
  phoneInputContainer: {
    display: 'flex',
    gap: theme.spacing(2),
    marginTop: theme.spacing(2),
    flexDirection: 'column',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
    },
  },
  countryCode: {
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '35%',
    },
  },
  phoneNumber: {
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '65%',
    },
  },
  errorText: {
    color: theme.palette.error.main,
    marginTop: theme.spacing(0.5),
    fontSize: '0.75rem',
  },
  validText: {
    color: theme.palette.success.main,
    marginTop: theme.spacing(0.5),
    fontSize: '0.75rem',
  },
  flagIcon: {
    marginRight: theme.spacing(1),
    fontSize: '1.2rem',
  },
  validationBox: {
    marginTop: theme.spacing(1),
    padding: theme.spacing(2),
    backgroundColor: '#f8f8f8',
    borderRadius: theme.spacing(1),
  },
  menuItem: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
  },
  welcomeText: {
    color: '#666',
    marginTop: theme.spacing(2),
    textAlign: 'center',
    [theme.breakpoints.down('xs')]: {
      fontSize: '1.2rem',
    },
  },
  descriptionText: {
    color: '#888',
    marginTop: theme.spacing(1),
    textAlign: 'center',
    [theme.breakpoints.down('xs')]: {
      fontSize: '0.875rem',
      padding: theme.spacing(0, 2),
    },
  },
  title: {
    color: '#333',
    marginBottom: theme.spacing(3),
    [theme.breakpoints.down('xs')]: {
      fontSize: '1.5rem',
      marginBottom: theme.spacing(2),
    },
  },
}));

const countryCodes = [
  { code: '+91', country: 'IN', flag: '🇮🇳' },
  { code: '+1', country: 'US', flag: '🇺🇸' },
  { code: '+44', country: 'UK', flag: '🇬🇧' },
  { code: '+61', country: 'AU', flag: '🇦🇺' },
  { code: '+86', country: 'CN', flag: '🇨🇳' },
  { code: '+49', country: 'DE', flag: '🇩🇪' },
  { code: '+33', country: 'FR', flag: '🇫🇷' },
  { code: '+81', country: 'JP', flag: '🇯🇵' },
  { code: '+7', country: 'RU', flag: '🇷🇺' },
  { code: '+55', country: 'BR', flag: '🇧🇷' },
  { code: '+27', country: 'ZA', flag: '🇿🇦' },
  { code: '+971', country: 'AE', flag: '🇦🇪' },
  { code: '+966', country: 'SA', flag: '🇸🇦' },
  { code: '+65', country: 'SG', flag: '🇸🇬' },
  { code: '+82', country: 'KR', flag: '🇰🇷' },
];

const Register = () => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const [userData, setUserData] = useState({
    name: '',
    companyName: '',
    address: '',
    countryCode: '+91',
    phoneNumber: '',
    email: '',
    password: '',
    confirmPassword: ''
  });

  const [errors, setErrors] = useState({});
  const [touched, setTouched] = useState({});
  
  const dispatch = useDispatch();
  const error = useSelector(state => state.auth.error);
  const history = useHistory();

  const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  const phonePattern = /^\d{10}$/;
  const passwordPattern = /^(?=.*[A-Z])(?=.*[!@#$%^&*])(?=.*[0-9]).{8,}$/;

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const handleClickShowPassword = (field) => {
    if (field === 'password') {
      setShowPassword(!showPassword);
    } else {
      setShowConfirmPassword(!showConfirmPassword);
    }
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const validateField = (name, value) => {
    let error = '';
    
    switch (name) {
      case 'name':
        error = value.trim() === '' ? 'Name is required' : '';
        break;
      case 'companyName':
        error = value.trim() === '' ? 'Company name is required' : '';
        break;
      case 'address':
        error = value.trim() === '' ? 'Address is required' : '';
        break;
      case 'phoneNumber':
        if (value.trim() === '') {
          error = 'Phone number is required';
        } else if (!phonePattern.test(value)) {
          error = 'Phone number must be 10 digits';
        }
        break;
      case 'email':
        if (value.trim() === '') {
          error = 'Email is required';
        } else if (!emailPattern.test(value)) {
          error = 'Invalid email format';
        }
        break;
      case 'password':
        if (value.trim() === '') {
          error = 'Password is required';
        } else if (!passwordPattern.test(value)) {
          error = 'Password must be at least 8 characters with 1 uppercase letter, 1 number, and 1 special character';
        }
        break;
      case 'confirmPassword':
        if (value.trim() === '') {
          error = 'Please confirm your password';
        } else if (value !== userData.password) {
          error = 'Passwords do not match';
        }
        break;
      default:
        break;
    }
    
    return error;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserData({ ...userData, [name]: value });
    
    if (!touched[name]) {
      setTouched({ ...touched, [name]: true });
    }
    
    const error = validateField(name, value);
    setErrors({ ...errors, [name]: error });

    if (name === 'password' && touched.confirmPassword) {
      const confirmError = userData.confirmPassword === '' 
        ? 'Please confirm your password' 
        : value !== userData.confirmPassword 
          ? 'Passwords do not match' 
          : '';
      setErrors(prev => ({ ...prev, confirmPassword: confirmError }));
    }
  };

  const handleBlur = (e) => {
    const { name, value } = e.target;
    setTouched({ ...touched, [name]: true });
    const error = validateField(name, value);
    setErrors({ ...errors, [name]: error });
  };

  const isFormValid = () => {
    const requiredFields = [
      'name', 'companyName', 'address', 'phoneNumber', 
      'email', 'password', 'confirmPassword'
    ];
    
    const hasEmptyFields = requiredFields.some(field => !userData[field]);
    if (hasEmptyFields) return false;
    
    const newErrors = {};
    requiredFields.forEach(field => {
      newErrors[field] = validateField(field, userData[field]);
    });
    
    setErrors(newErrors);
    return !Object.values(newErrors).some(error => error !== '');
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    
    if (isFormValid()) {
      const fullUserData = {
        ...userData,
        phoneNumber: userData.countryCode + userData.phoneNumber,
        registerType: 'selfLogin'
      };
      dispatch(register(fullUserData));
    } else {
      const allTouched = {};
      Object.keys(userData).forEach(key => {
        allTouched[key] = true;
      });
      setTouched(allTouched);
      
      const newErrors = {};
      Object.entries(userData).forEach(([field, value]) => {
        newErrors[field] = validateField(field, value);
      });
      setErrors(newErrors);
    }
  };

  return (
    <div className={classes.root}>
      <Paper className={classes.paper} elevation={6}>
        <Box className={classes.logoSection}>
          <img src={logo} alt="Markaible Logo" className={classes.logo} />
          <Typography variant={isMobile ? "h6" : "h5"} className={classes.welcomeText}>
            Join Markaible
          </Typography>
          <Typography variant="body2" className={classes.descriptionText}>
            Create your account and start managing your business
          </Typography>
        </Box>
        <Box className={classes.formSection}>
          <Typography component="h1" variant={isMobile ? "h5" : "h4"} className={classes.title}>
            Create Account
          </Typography>
          <form className={classes.form} noValidate onSubmit={handleSubmit}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="name"
              label="Full Name"
              name="name"
              autoComplete="name"
              autoFocus
              value={userData.name}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.name && Boolean(errors.name)}
              helperText={touched.name && errors.name}
            />
            
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="companyName"
              label="Company Name"
              name="companyName"
              autoComplete="organization"
              value={userData.companyName}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.companyName && Boolean(errors.companyName)}
              helperText={touched.companyName && errors.companyName}
            />
            
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="address"
              label="Address"
              name="address"
              autoComplete="street-address"
              multiline
              rows={2}
              value={userData.address}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.address && Boolean(errors.address)}
              helperText={touched.address && errors.address}
            />
            
            <Box className={classes.phoneInputContainer}>
              <FormControl variant="outlined" className={classes.countryCode}>
                <InputLabel id="country-code-label">Code</InputLabel>
                <Select
                  labelId="country-code-label"
                  id="countryCode"
                  name="countryCode"
                  value={userData.countryCode}
                  onChange={handleChange}
                  label="Code"
                >
                  {countryCodes.map((country) => (
                    <MenuItem key={country.code} value={country.code} className={classes.menuItem}>
                      <span className={classes.flagIcon}>{country.flag}</span>
                      <span>{country.code}</span>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              
              <TextField
                variant="outlined"
                required
                className={classes.phoneNumber}
                id="phoneNumber"
                label="Phone Number"
                name="phoneNumber"
                autoComplete="tel"
                value={userData.phoneNumber}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.phoneNumber && Boolean(errors.phoneNumber)}
                helperText={touched.phoneNumber && errors.phoneNumber}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <span className={classes.flagIcon}>
                        {countryCodes.find(c => c.code === userData.countryCode)?.flag}
                      </span>
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
            
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              value={userData.email}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.email && Boolean(errors.email)}
              helperText={touched.email && errors.email}
            />
            
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type={showPassword ? 'text' : 'password'}
              id="password"
              value={userData.password}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.password && Boolean(errors.password)}
              helperText={touched.password && errors.password}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => handleClickShowPassword('password')}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            
            {touched.password && userData.password && (
              <Box className={classes.validationBox}>
                <Typography variant="caption" className={
                  userData.password.length >= 8 ? classes.validText : classes.errorText
                }>
                  {userData.password.length >= 8 ? "✓" : "✗"} At least 8 characters
                </Typography>
                <br />
                <Typography variant="caption" className={
                  /[A-Z]/.test(userData.password) ? classes.validText : classes.errorText
                }>
                  {/[A-Z]/.test(userData.password) ? "✓" : "✗"} At least 1 uppercase letter
                </Typography>
                <br />
                <Typography variant="caption" className={
                  /[0-9]/.test(userData.password) ? classes.validText : classes.errorText
                }>
                  {/[0-9]/.test(userData.password) ? "✓" : "✗"} At least 1 number
                </Typography>
                <br />
                <Typography variant="caption" className={
                  /[!@#$%^&*]/.test(userData.password) ? classes.validText : classes.errorText
                }>
                  {/[!@#$%^&*]/.test(userData.password) ? "✓" : "✗"} At least 1 special character
                </Typography>
              </Box>
            )}
            
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="confirmPassword"
              label="Confirm Password"
              type={showConfirmPassword ? 'text' : 'password'}
              id="confirmPassword"
              value={userData.confirmPassword}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.confirmPassword && Boolean(errors.confirmPassword)}
              helperText={touched.confirmPassword && errors.confirmPassword}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => handleClickShowPassword('confirmPassword')}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              Register
            </Button>
            
            {error && (
              <Typography color="error" style={{ marginTop: 16, textAlign: 'center' }}>
                {error}
              </Typography>
            )}
            
            <Box className={classes.loginLink}>
              <Typography variant="body2">
                Already have an account? <Link to="/login" style={{ color: '#3f51b5' }}>Sign in</Link>
              </Typography>
            </Box>
          </form>
        </Box>
      </Paper>
    </div>
  );
};

export default Register;
